import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { PrivateRoute } from "./modules/base";
import { ToastContainer } from "react-toastify";
import "./modules/auth";
import "./modules/classes";
import "./modules/labels";
import "./modules/pdfs";
import "./modules/tools";
import "./modules/reviews";
import "./modules/users";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "simplebar-react/dist/simplebar.min.css";
import "./modules/core-ui/scss/style.scss";
import RouteRegistry from "./modules/base/redux/RouteRegistry";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBkJx69S3tYJtj61_gufQlBVsIUIB7cbd4",
    authDomain: "finpeace-bd050.firebaseapp.com",
    projectId: "finpeace-bd050",
    storageBucket: "finpeace-bd050.appspot.com",
    messagingSenderId: "762107130457",
    appId: "1:762107130457:web:fbb74c9848e4380415f748",
    measurementId: "G-LEBQHQ92R8",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const messaging = getMessaging();
getToken(messaging, {})
    .then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            console.log("AnhLH currentToken", currentToken);
        } else {
            // Show permission request UI
            console.log(
                "No registration token available. Request permission to generate one."
            );
            // ...
        }
    })
    .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
    });

const routes = RouteRegistry.getRoutes();

function App() {
    const history = useHistory();
    const { auth } = useSelector((state: { auth: any }) => state);

    useEffect(() => {
        if (auth.token == null) {
            if (history.location.pathname !== "/login") {
                history.push("/login");
            }
        } else {
            if (history.location.pathname === "/login") {
                history.push("/");
            }
        }
    }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <ToastContainer />
            <Switch>
                {routes.map((route: any) => {
                    const RouteComponent = route.noAuthen
                        ? Route
                        : PrivateRoute;
                    return (
                        <RouteComponent
                            exact
                            key={route.path}
                            path={route.path}
                            permision={route.permision}
                            component={route.component}
                        />
                    );
                })}
            </Switch>
        </>
    );
}

export default App;
