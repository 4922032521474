import { Dispatch, Store } from "redux";
import { ACTION_TYPES } from "./actionTypes";
import { MiddlewareRegistry } from "modules/base";
import {
  apiGetClasses,
  apiAddClass,
  apiUpdateClass,
  apiGetReportTeachers,
  apiGetOptions,
  apiGetReportStudents,
  apiGetTransactions,
  apiUpdateTransaction,
  apiAddTransaction,
  apiGetReportTransactions,
  apiDeleteClass,
  apiGetClassAttendance,
  apiPostClassAttendance,
} from "./services";
import {
  setClassAttendance,
  setClasses,
  setOptions,
  setReportStudents,
  setReportTeachers,
  setReportTransactions,
  setTransactions,
} from "./actions";
import { toastNotify } from "modules/common";
import { array2str } from "../utils/arrayFunc";
import { GetAttendanceParams } from "../types/actionType";
import { GetClassAttendanceResonseType } from "../types/httpResponseType";
import { ClassesStoreType } from "../types/storeType";
import { PostClassAttendance } from "../types/httpRequest";

export const middleware =
  ({ dispatch, getState }: Store) =>
    (next: Function) =>
      async (action: any) => {
        next(action);
        switch (action.type) {
          case ACTION_TYPES.GET_CLASS_ATTENDANCE: {
            await handleGetClassAttendance(dispatch, action.params, getState);
            return;
          }
          case ACTION_TYPES.POST_CLASS_ATTENDANCE: {
            await handlePostClassAttendance(action.params);
            return;
          }
          case ACTION_TYPES.GET_CLASSES: {
            dispatch(
              setClasses({
                data: [],
                page: action.data,
                loading: true,
              })
            );
            apiGetClasses({
              address_id: action.address_id,
              page: action.data,
              size: action.size,
              from_date: action?.localFilters?.dateFrom,
              to_date: action?.localFilters?.dateTo,
              teacher_id:
                action.filters?.teacher_id &&
                  action.filters?.teacher_id?.length !== 0
                  ? action.filters?.teacher_id[0]
                  : undefined,
            })
              .then((rs) => {
                dispatch(setClasses({ ...rs, loading: false }));
              })
              .catch((err) => { });
            return;
          }
          case ACTION_TYPES.DELETE_CLASS: {
            apiDeleteClass(action.id, action.typeDelete).then((rs: any) => {
              toastNotify("Xoá lớp thành công!", "info");
              action.cbDeleteClass();
            });
            return;
          }
          case ACTION_TYPES.ADD_CLASS: {
            apiAddClass(action.data).then((rs: any) => {
              toastNotify("Thêm lớp thành công!", "info");
              action.cbAddClass();
            });
            return;
          }
          case ACTION_TYPES.UPDATE_CLASS: {
            apiUpdateClass(action.data).then((rs: any) => {
              toastNotify("Sửa lớp thành công!", "info");
              action.cbAddClass();
            });
            return;
          }
          case ACTION_TYPES.GET_TEACHER_REPORT: {
            dispatch(
              setReportTeachers({
                data: [],
                page: action.data,
                loading: true,
              })
            );
            apiGetReportTeachers({
              page: action.data,
              size: action.size,
              from_date: action?.localFilters?.dateFrom,
              to_date: action?.localFilters?.dateTo,
              address_id: action.address_id,
              teacher_id:
                action.filters?.teacher_id &&
                  action.filters?.teacher_id?.length !== 0
                  ? action.filters?.teacher_id[0]
                  : undefined,
            })
              .then((rs) => {
                dispatch(
                  setReportTeachers({
                    ...rs,
                    loading: false,
                  })
                );
              })
              .catch((err) => { });
            return;
          }
          case ACTION_TYPES.GET_OPTIONS: {
            apiGetOptions(action.params).then((rs: any) => {
              dispatch(
                setOptions({
                  ...rs,
                  students: rs.students.map((s: any) => {
                    return { ...s, label: s.name, value: s.id };
                  }),
                  teachers: rs.teachers.map((t: any) => {
                    return { ...t, label: t.name, value: t.id };
                  }),
                  v2Students: rs.v2Students.map((s: any) => ({
                    ...s,
                    label: s.name,
                    value: s.id,
                  })),
                  v2Teachers: rs.v2Teachers.map((s: any) => ({
                    ...s,
                    label: s.name,
                    value: s.id,
                  })),
                })
              );
            });
            return;
          }
          case ACTION_TYPES.GET_STUDENT_REPORT: {
            dispatch(
              setReportStudents({
                data: [],
                page: action.data,
                loading: true,
              })
            );
            apiGetReportStudents({
              page: action.data,
              size: action.size,
              from_date: action?.localFilters?.dateFrom,
              to_date: action?.localFilters?.dateTo,
              address_id: action.address_id,
              teacher_id:
                action.filters?.teacher_id &&
                  action.filters?.teacher_id?.length !== 0
                  ? action.filters?.teacher_id[0]
                  : undefined,
              student_id:
                action.filters?.student_id &&
                  action.filters?.student_id?.length !== 0
                  ? action.filters?.student_id[0]
                  : undefined,
            })
              .then((rs) => {
                dispatch(setReportStudents({ ...rs, loading: false }));
              })
              .catch((err) => { });
            return;
          }
          case ACTION_TYPES.GET_TRANSACTIONS: {
            dispatch(
              setTransactions({
                data: [],
                page: action.data,
                loading: true,
              })
            );
            apiGetTransactions({
              page: action.data,
              size: action.size,
              address_id: action.address_id,
              from_date: action?.localFilters?.dateFrom,
              to_date: action?.localFilters?.dateTo,
              type: action?.typet,
              user_id: array2str(action.filters?.user_id, 1),
            })
              .then((rs) => {
                dispatch(setTransactions({ ...rs, loading: false }));
              })
              .catch((err) => { });
            return;
          }
          case ACTION_TYPES.UPDATE_TRANSACTION: {
            apiUpdateTransaction({
              id: action.data,
              from_date: action.localFilters?.dateFrom,
              to_date: action.localFilters?.dateTo,
            }).then((rs: any) => {
              toastNotify("Thanh toán thành công!", "info");
              action.cbUpdateTransaction();
            });
            return;
          }
          case ACTION_TYPES.ADD_TRANSACTION: {
            apiAddTransaction({ ...action.data }).then((rs: any) => {
              toastNotify("Thêm khoản chi phát sinh thành công!", "info");
            });
            return;
          }
          case ACTION_TYPES.GET_REPORT_TRANSACTIONS: {
            apiGetReportTransactions(action.params).then((rs: any) => {
              dispatch(setReportTransactions(rs));
            });
            return;
          }
        }
      };

MiddlewareRegistry.register(middleware);

const handleGetClassAttendance = async (
  dispatch: Dispatch,
  params: GetAttendanceParams,
  getState: () => { classes: ClassesStoreType }
) => {
  const { classAttendance } = getState().classes;
  if (classAttendance.class_id !== params.class_id) {
    const rs: GetClassAttendanceResonseType = await apiGetClassAttendance(
      params.class_id
    );
    if (rs.status) {
      dispatch(
        setClassAttendance({ class_id: params.class_id, data: rs.data })
      );
    }
  }
};

const handlePostClassAttendance = async (params: {
  class_id: string;
  data: PostClassAttendance;
}) => {
  await apiPostClassAttendance(params.class_id, params.data);
};
