export const ACTION_TYPES = {
  GET_USERS: Symbol("GET_USERS"),
  SET_USERS: Symbol("SET_USERS"),
  SET_USER: Symbol("SET_USER"),
  UPDATE_PERMISION_USER: Symbol("UPDATE_PERMISION_USER"),
  UPDATE_USER: Symbol("UPDATE_USER"),
  ADD_USER: Symbol("ADD_USER"),
  ADD_PERMISION: Symbol("ADD_PERMISION"),
  DELETE_PERMISION: Symbol("DELETE_PERMISION"),
};
