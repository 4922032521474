import { ReducerRegistry, TAction } from "modules/base";
import { ToolStore } from "../types/store";
import { ACTION_TYPES } from "./actionTypes";

const initState: ToolStore = {
  tailieu: {
    captchaUrl: "",
    q: {},
    isCallingCaptcha: false,
    isGettingFile: false,
    fileUrl: "",
  },
};

ReducerRegistry.register("tools", (state = initState, action: TAction) => {
  switch (action.type) {
    case ACTION_TYPES.SET_TAILIEU_DATA:
      return { ...state, tailieu: { ...state.tailieu, ...action.data } };
    default:
      return state;
  }
});
