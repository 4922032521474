import { toast } from "react-toastify";
import { timestampToYYYYMMDDV3 } from "modules/common";
import {
  RateOfReviewByDateType,
  StatusOfReviewByDateType,
} from "../types/reviewStore";

export const toastNotify = (text: string, type?: string) => {
  switch (type) {
    case "info":
      toast.info(text);
      break;
    case "success":
      toast.success(text);
      break;
    default:
      toast.error(text);
      break;
  }
};

export const renderDataLabel = () => {
  const days = [];
  const date = new Date();
  for (let index = 0; index <= 31; index++) {
    days.push(index);
  }
  return days.map((d) => {
    return timestampToYYYYMMDDV3(date.getTime() - 86400000 * d);
  });
};

export const renderDataLabelByCreatedAtReview = (data: any) => {
  const days = [];
  const date = new Date();
  for (let index = 0; index <= 31; index++) {
    days.push(index);
  }
  return days.map((day) => {
    const temp = data.find(
      (d: any) => d.c === timestampToYYYYMMDDV3(date.getTime() - 86400000 * day)
    );
    return temp?.total || 0;
  });
};

export const renderDataComment = (data: any) => {
  const days = [];
  const date = new Date();
  for (let index = 0; index <= 31; index++) {
    days.push(index);
  }
  return days.map((day) => {
    const temp = data.find(
      (d: any) =>
        d.date === timestampToYYYYMMDDV3(date.getTime() - 86400000 * day)
    );
    return temp?.total || 0;
  });
};

export const renderDataRateV2 = (data: any) => {
  const days: any = [];
  const date = new Date();
  for (let index = 0; index <= 31; index++) {
    days.push(index);
  }

  return [
    {
      label: "IOS",
      data: days.map((day: any) => {
        return data
          .filter(
            (d: any) =>
              d.date ===
              timestampToYYYYMMDDV3(date.getTime() - 86400000 * day) &&
              d.os === "IOS"
          )
          .reduce((total: any, data: any) => {
            return total + Math.round(parseInt(data?.total));
          }, 0);
      }),
    },
    {
      label: "Android",
      data: days.map((day: any) => {
        return data
          .filter(
            (d: any) =>
              d.date ===
              timestampToYYYYMMDDV3(date.getTime() - 86400000 * day) &&
              d.os === "Android"
          )
          .reduce((total: any, data: any) => {
            return total + Math.round(parseInt(data?.total));
          }, 0);
      }),
    },
    {
      label: "IOS-Chưa phản hồi",
      data: days.map((day: any) => {
        const temp = data.find(
          (d: any) =>
            d.date === timestampToYYYYMMDDV3(date.getTime() - 86400000 * day) &&
            d.isReplyed === 0 &&
            d.os === "IOS"
        );
        return temp?.total || 0;
      }),
    },
    {
      label: "IOS-Đã phản hồi",
      data: days.map((day: any) => {
        const temp = data.find(
          (d: any) =>
            d.date === timestampToYYYYMMDDV3(date.getTime() - 86400000 * day) &&
            d.isReplyed === 1 &&
            d.os === "IOS"
        );
        return temp?.total || 0;
      }),
    },
    {
      label: "Android-Chưa phản hồi",
      data: days.map((day: any) => {
        const temp = data.find(
          (d: any) =>
            d.date === timestampToYYYYMMDDV3(date.getTime() - 86400000 * day) &&
            d.isReplyed === 0 &&
            d.os === "Android"
        );
        return temp?.total || 0;
      }),
    },
    {
      label: "Android-Đã phản hồi",
      data: days.map((day: any) => {
        const temp = data.find(
          (d: any) =>
            d.date === timestampToYYYYMMDDV3(date.getTime() - 86400000 * day) &&
            d.isReplyed === 1 &&
            d.os === "Android"
        );
        return temp?.total || 0;
      }),
    },
  ];
};

export const renderDataRate = (data: any) => {
  const days = [];
  const date = new Date();
  for (let index = 0; index <= 31; index++) {
    days.push(index);
  }
  return days.map((day) => {
    const temp = data.find(
      (d: any) =>
        d.date === timestampToYYYYMMDDV3(date.getTime() - 86400000 * day)
    );
    return temp?.total || 0;
  });
};

export const renderDataReviewStatus = (datas: any, filter: any) => {
  return {
    label: [
      "Chưa phản hồi IOS",
      "Đã phản hồi IOS",
      "Chưa phản hồi Android",
      "Đã phản hồi Android",
    ],
    data: [
      datas
        .filter(
          (r: any) =>
            r.isReplyed === 0 &&
            r.os === "IOS" &&
            r.date >= filter.start &&
            r.date <= filter.end
        )
        .reduce((total: any, data: any) => {
          return total + Math.round(parseInt(data?.total));
        }, 0),
      datas
        .filter(
          (r: any) =>
            r.isReplyed === 1 &&
            r.os === "IOS" &&
            r.date >= filter.start &&
            r.date <= filter.end
        )
        .reduce((total: any, data: any) => {
          return total + Math.round(parseInt(data?.total));
        }, 0),
      datas
        .filter(
          (r: any) =>
            r.isReplyed === 0 &&
            r.os === "Android" &&
            r.date >= filter.start &&
            r.date <= filter.end
        )
        .reduce((total: any, data: any) => {
          return total + Math.round(parseInt(data?.total));
        }, 0),
      datas
        .filter(
          (r: any) =>
            r.isReplyed === 1 &&
            r.os === "Android" &&
            r.date >= filter.start &&
            r.date <= filter.end
        )
        .reduce((total: any, data: any) => {
          return total + Math.round(parseInt(data?.total));
        }, 0),
    ],
  };
};

export const renderReportLabels = (start: number = 0, end: number = 30) => {
  const days: any[] = [];
  const date = new Date();
  for (let index = end; index > start; index--) {
    days.push(index);
  }

  return days.map((d: any) => {
    return timestampToYYYYMMDDV3(date.getTime() - 86400000 * (d - 1));
  });
};

export const renderReportWeekLabels = (start: number = 0, end: number = 10) => {
  const days: any[] = [];
  const date = new Date();
  const dayOfWeek = date.getDay();
  for (let index = end; index > start; index--) {
    days.push(index);
  }

  return days.map((d: any) => {
    return {
      label: timestampToYYYYMMDDV3(
        date.getTime() - 7 * 86400000 * (d - 1) - (dayOfWeek - 1) * 86400000
      ),
      dayInWeeks: [
        timestampToYYYYMMDDV3(
          date.getTime() -
          7 * 86400000 * (d - 2) -
          0 * 86400000 -
          (dayOfWeek - 1) * 86400000
        ),
        timestampToYYYYMMDDV3(
          date.getTime() -
          7 * 86400000 * (d - 2) -
          1 * 86400000 -
          (dayOfWeek - 1) * 86400000
        ),
        timestampToYYYYMMDDV3(
          date.getTime() -
          7 * 86400000 * (d - 2) -
          2 * 86400000 -
          (dayOfWeek - 1) * 86400000
        ),
        timestampToYYYYMMDDV3(
          date.getTime() -
          7 * 86400000 * (d - 2) -
          3 * 86400000 -
          (dayOfWeek - 1) * 86400000
        ),
        timestampToYYYYMMDDV3(
          date.getTime() -
          7 * 86400000 * (d - 2) -
          4 * 86400000 -
          (dayOfWeek - 1) * 86400000
        ),
        timestampToYYYYMMDDV3(
          date.getTime() -
          7 * 86400000 * (d - 2) -
          5 * 86400000 -
          (dayOfWeek - 1) * 86400000
        ),
        timestampToYYYYMMDDV3(
          date.getTime() -
          7 * 86400000 * (d - 2) -
          6 * 86400000 -
          (dayOfWeek - 1) * 86400000
        ),
      ],
    };
  });
};

export const renderReportLabelOfReviewByDates = () => {
  const days: any[] = [];
  for (let index = 30; index > 0; index--) {
    days.push(index);
  }

  const labels = ["a", "b"];
  return labels.map((l) => {
    return { label: l, data: days.map((d) => 1) };
  });
};

export const renderReportStatusOfReviewByDates = (
  statusOfReviewByDates: StatusOfReviewByDateType[],
  start: number = 0,
  end: number = 30
) => {
  const statusLabels = {
    "IOS-1": "Đã phản hồi IOS",
    "IOS-0": "Chưa phản hồi IOS",
    "Android-1": "Đã phản hồi Android",
    "Android-0": "Chưa phản hồi Android",
  };
  const days: any[] = [];
  const date = new Date();
  for (let index = end; index > start; index--) {
    days.push(index);
  }
  const dates = days.map((d: any) => {
    return timestampToYYYYMMDDV3(date.getTime() - 86400000 * d);
  });

  const statuses = [
    { os: "IOS", status: 1 },
    { os: "IOS", status: 0 },
    { os: "Android", status: 1 },
    { os: "Android", status: 0 },
  ];
  return statuses.map((metaStatus) => {
    return {
      label: statusLabels[`${metaStatus.os}-${metaStatus.status}`],
      data: dates.map((d) =>
        statusOfReviewByDates
          .filter(
            (s) =>
              s.date === d &&
              s.os === metaStatus.os &&
              s.status === metaStatus.status
          )
          .reduce((total, status) => {
            return total + 0 + parseInt("" + status.total);
          }, 0)
      ),
    };
  });
};

export const renderReportRateAverageByDates = (
  rateOfReviewByDates: RateOfReviewByDateType[],
  start: number = 0,
  end: number = 30
) => {
  try {
    const days: any[] = [];
    const date = new Date();
    for (let index = end; index > start; index--) {
      days.push(index);
    }
    const dates = days.map((d: any) => {
      return timestampToYYYYMMDDV3(date.getTime() - 86400000 * d);
    });

    const rates = ["avarage"];
    return rates.map((metaRate) => {
      return {
        label: metaRate,
        data: dates.map(
          (d) =>
            (rateOfReviewByDates
              .filter((r) => r.date === d && r.rate === 1)
              .reduce((total, rate) => {
                return total + 0 + parseInt(rate.total + "");
              }, 0) +
              rateOfReviewByDates
                .filter((r) => r.date === d && r.rate === 2)
                .reduce((total, rate) => {
                  return total + 0 + parseInt(rate.total + "");
                }, 0) *
              2 +
              rateOfReviewByDates
                .filter((r) => r.date === d && r.rate === 3)
                .reduce((total, rate) => {
                  return total + 0 + parseInt(rate.total + "");
                }, 0) *
              3 +
              rateOfReviewByDates
                .filter((r) => r.date === d && r.rate === 4)
                .reduce((total, rate) => {
                  return total + 0 + parseInt(rate.total + "");
                }, 0) *
              4 +
              rateOfReviewByDates
                .filter((r) => r.date === d && r.rate === 5)
                .reduce((total, rate) => {
                  return total + 0 + parseInt(rate.total + "");
                }, 0) *
              5) /
            rateOfReviewByDates
              .filter((r) => r.date === d)
              .reduce((total, rate) => {
                return total + 0 + parseInt(rate.total + "");
              }, 0)
        ),
      };
    });
  } catch (error) { }
};

export const renderReportOfLabelAddbyReviewDate = (
  labels: {}[],
  labelByDateCreateReview: {}[]
) => {
  const days: any[] = [];
  for (let index = 0; index <= 31; index++) {
    days.push(index);
  }

  const date = new Date();
  const dataLabels = days.map((d: any) => {
    return timestampToYYYYMMDDV3(date.getTime() - 86400000 * d);
  });
  const dataValue = labels
    .filter((l: any) => l.level === 2)
    .map((l: any) => {
      return {
        label: l.label,
        data: days.map((d: any) => {
          const temp: any = labelByDateCreateReview.find(
            (label: any) =>
              label.v === l.id &&
              label.c === timestampToYYYYMMDDV3(date.getTime() - 86400000 * d)
          );
          const tempAll: any = labelByDateCreateReview.filter(
            (label: any) =>
              label.c === timestampToYYYYMMDDV3(date.getTime() - 86400000 * d)
          );
          const total = tempAll.reduce((total: any, data: any) => {
            return total + Math.round(parseInt(data?.total));
          }, 0);
          return total === 0
            ? 0
            : Math.floor((parseInt(temp?.total || 0) / total) * 10) / 10;
        }),
      };
    });

  return { dataLabels, dataValue };
};
