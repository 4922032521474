import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOptions, getV2Reports } from "../redux/actions";
import { DatePicker, Divider, Select } from "antd";
import { ReviewStoreType } from "../types/reviewStore";
import { Link, useLocation } from "react-router-dom";
import { CRow } from "@coreui/react";
import { Chart, PieChart } from "modules/common";
import {
  renderReportLabels,
  renderReportRateAverageByDates,
  renderReportStatusOfReviewByDates,
} from "../redux/functions";
import ChartX from "./ChartX";
import ChartY from "./ChartY";
import ChartXWeek from "./ChartXWeek";
import "../assets/scss/reports.scss";

const { RangePicker } = DatePicker;

const getSize = (number: number) => {
  return Math.sqrt(number) + 10;
};

const STATUS_REVIEWS = {
  0: "Chưa phản hồi",
  1: "Đã phản hồi",
};

const V2Report = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);

  const { v2Report, options } = useSelector(
    (state: { reviews: ReviewStoreType }) => state.reviews
  );

  const [dataReportRateAverageByDates, setDataReportRateAverageByDates] =
    React.useState<number[]>([]);

  const [filter, setFilter] = React.useState<{
    end?: number;
    start?: number;
    f: number;
    t: number;
  }>({ f: 0, t: 30 });

  useEffect(() => {
    const data: any = renderReportRateAverageByDates(
      v2Report.rateOfReviewByDates,
      filter.f,
      filter.t
    );
    setDataReportRateAverageByDates(data[0].data);
  }, [v2Report, filter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getV2Reports({ productId: query.get("productId") }));
    dispatch(getOptions());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePlatform = (e: string) => {
    const data: any = {
      ...filter,
      platform: e,
      productId: query.get("productId"),
    };
    setFilter(data);
    dispatch(getV2Reports(data));
  };

  const handleChangeVersion = (e: string) => {
    const data: any = {
      ...filter,
      v: e,
      productId: query.get("productId"),
    };
    setFilter(data);
    dispatch(getV2Reports(data));
  };

  const handleChangeDateRange = (date: any, dateString: any) => {
    const dateStart = new Date(dateString[0]);
    const dateEnd = new Date(dateString[1]);
    const d = new Date();
    const data: any = {
      ...filter,
      start: dateStart.getTime() / 1000,
      end: dateEnd.getTime() / 1000,
      productId: query.get("productId"),
      t: Math.floor((d.getTime() / 1000 - dateStart.getTime() / 1000) / 86400),
      f: Math.floor((d.getTime() / 1000 - dateEnd.getTime() / 1000) / 86400),
    };
    setFilter(data);
    dispatch(getV2Reports(data));
  };

  const handleChangeLabel = (e: string) => {
    const data: any = {
      ...filter,
      label: e,
      productId: query.get("productId"),
    };
    setFilter(data);
    dispatch(getV2Reports(data));
  };

  const platforms: { label: string; value: string }[] = [
    {
      label: "Chọn flatform",
      value: "'IOS','Android'",
    },
    {
      label: "IOS",
      value: "'IOS'",
    },
    {
      label: "Android",
      value: "'Android'",
    },
  ];

  return (
    <div id="reports-review">
      <RangePicker onChange={handleChangeDateRange} />
      <Select
        onChange={handleChangeLabel}
        style={{ width: 200 }}
        options={options.labels
          .filter((l) => l.level === 2)
          .map((l) => ({ ...l, label: l.name, value: l.id }))}
        placeholder="Chọn label"
      />
      <Select
        defaultValue="'IOS','Android'"
        onChange={handleChangePlatform}
        style={{ width: 200 }}
        options={platforms}
      />
      <Select
        defaultValue=""
        onChange={handleChangeVersion}
        placeholder="Chọn version"
        style={{ width: 200 }}
        options={[
          ...[
            {
              label: "Chọn version",
              value: "",
            },
          ],
          ...options.versionNames.map((v) => ({
            label: v.appVersionName,
            value: v.appVersionName,
          })),
        ]}
      />
      <Divider />
      <div className="label-wrap">
        {v2Report.labelOfReviews.map((l) => {
          return (
            <Link
              className="label"
              style={{ fontSize: `${getSize(l.total)}px` }}
              to={`/reviews?productId=${query.get("productId")}&labels=${l.id}`}
              key={l.id}
            >{`${l.name}(${l.total})`}</Link>
          );
        })}
      </div>
      <Divider />
      <ChartX
        datas={v2Report.labelOfReviewByDates.map((l) => ({
          name: l.name,
          date: l.date,
          total: 1,
        }))}
        filter={filter}
        labels={v2Report.labelOfReviews
          .sort((b, a) => a.total - b.total)
          .slice(0, 10)}
        title={"Thông kê label theo ngày"}
      />
      <br />
      <ChartXWeek
        datas={v2Report.labelOfReviewByDates.map((l) => ({
          name: l.name,
          date: l.date,
          total: 1,
        }))}
        filter={filter}
        labels={v2Report.labelOfReviews
          .sort((b, a) => a.total - b.total)
          .slice(0, 10)}
        title={"Thông kê label theo tuần"}
      />
      <br />
      <ChartX
        datas={v2Report.rateOfReviewByDates.map((l) => ({
          name: l.rate + "",
          date: l.date,
          total: parseInt(l.total),
        }))}
        filter={filter}
        labels={v2Report.rateOfReviews
          .filter((r) => r.rate > 0)
          .sort((b, a) => a.rate - b.rate)
          .map((r) => ({ name: r.rate + "" }))}
        title={"Thông kê số sao đánh giá"}
      />
      <Divider />
      <ChartY
        title={"Điểm review trung bình"}
        datas={renderReportLabels(filter.f, filter.t).map((l, index) => {
          const d = new Date(l);
          return {
            x: d.getTime(),
            y: dataReportRateAverageByDates[index],
          };
        })}
      />
      <Divider />
      <Chart
        labels={renderReportLabels(filter.f, filter.t)}
        data={renderReportStatusOfReviewByDates(
          v2Report.statusOfReviewByDates,
          filter.f,
          filter.t
        )}
        title={"Thông kê số lượt đánh giá"}
      />
      <Divider />
      <CRow>
        <PieChart
          title={"Lượt phản hồi theo user"}
          size={3}
          labels={v2Report.createByOfComments
            .filter((c) => c.createdBy)
            .map((c) => c.createdBy)}
          data={v2Report.createByOfComments
            .filter((c) => c.createdBy)
            .map((c) => c.total)}
        />
        <PieChart
          title={"Số * đánh giá"}
          size={3}
          labels={v2Report.rateOfReviews
            .filter((c) => c.rate)
            .map((c) => c.rate)}
          data={v2Report.rateOfReviews
            .filter((c) => c.rate)
            .map((c) => c.total)}
        />
        <PieChart
          title={"Review đã phản hồi / chưa phản hồi"}
          size={3}
          labels={v2Report.statusOfReviews.map(
            (s) => `${STATUS_REVIEWS[s.status]} ${s.os}`
          )}
          data={v2Report.statusOfReviews.map((s) => s.total)}
        />
        <PieChart
          title={"Số review gán nhãn"}
          size={3}
          labels={v2Report.createByOfLabelReviews.map((c) => c.createdBy)}
          data={v2Report.createByOfLabelReviews.map((c) => c.total)}
        />
      </CRow>
    </div>
  );
};

export default V2Report;
