export const formatDate = (date: string) => {
  try {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    return `${year}-${month}-${day < 10 ? "0" + day : day}`;
  } catch (error) {
    return "2023-01-01";
  }
};
