import { TLoginResponses } from "../types/responses";

export const convertLoginResponsetoAuthStore = (rs: TLoginResponses) => {
    return {
        email: rs.email,
        id: rs.id,
        namme: rs.namme,
        username: rs.username,
        permisions: rs.permisions
    };
}