import { ReducerRegistry, TAction } from "modules/base";
import { ClassesStoreType } from "../types/storeType";
import { ACTION_TYPES } from "./actionTypes";

const initState: ClassesStoreType = {
  classes: { data: [], total: 0, page: 1, loading: true },
  reportTeachers: { data: [], total: 0, page: 1, loading: true },
  reportStudents: { data: [], total: 0, page: 1, loading: true },
  transactions: { data: [], total: 0, page: 1, loading: true, sum: [] },
  options: {
    subjects: [],
    roles: {},
    classTypes: [],
    curricolums: [],
    v2Students: [],
    v2Teachers: [],
    rates: [],
  },
  classAttendance: { class_id: "", data: [] },
  reportTransactions: { doneBalances: [], notDoneBalances: [] },
};

ReducerRegistry.register("classes", (state = initState, action: TAction) => {
  switch (action.type) {
    case ACTION_TYPES.SET_CLASS_ATTENDANCE:
      return {
        ...state,
        classAttendance: { ...state.classAttendance, ...action.data },
      };
    case ACTION_TYPES.SET_CLASSES:
      return { ...state, classes: { ...action.data } };
    case ACTION_TYPES.SET_TEACHER_REPORT:
      return { ...state, reportTeachers: { ...action.data } };
    case ACTION_TYPES.SET_STUDENT_REPORT:
      return { ...state, reportStudents: { ...action.data } };
    case ACTION_TYPES.SET_OPTIONS:
      return { ...state, options: action.data };
    case ACTION_TYPES.SET_TRANSACTIONS:
      return {
        ...state,
        transactions: { ...state.transactions, ...action.data },
      };
    case ACTION_TYPES.SET_REPORT_TRANSACTIONS: {
      return {
        ...state,
        reportTransactions: action.data,
      };
    }
    default:
      return state;
  }
});
