import { ReducerRegistry, TAction } from "modules/base";
import { ReviewStoreType } from "../types/reviewStore";
import { ACTION_TYPES } from "./actionTypes";

const initState: ReviewStoreType = {
  rateComments: { data: [], total: 0, page: 1, loading: true },
  reviews: { data: [], total: 0, page: 1, loading: true },
  review: { loading: null },
  options: { labels: [], versionNames: [] },
  reviewLabel: [],
  reports: {
    reviews: [],
    comments: [],
    commentTotals: [],
    rateTotals: [],
    rates: [],
    rateByOSAndStatusTotals: [],
    labels: [],
    createrLabels: [],
    labelByDateCreateReview: [],
  },
  v2Report: {
    labelOfReviews: [],
    createByOfComments: [],
    rateOfReviews: [],
    statusOfReviews: [],
    createByOfLabelReviews: [],
    labelOfReviewByDates: [],
    rateOfReviewByDates: [],
    statusOfReviewByDates: [],
  },
  rateComment: { like: 0, dislike: 0, isRating: false },
};

ReducerRegistry.register("reviews", (state = initState, action: TAction) => {
  switch (action.type) {
    case ACTION_TYPES.SET_OPTIONS:
      return { ...state, options: action.data };
    case ACTION_TYPES.SET_RATE_COMMENTS:
      return {
        ...state,
        rateComments: { ...state.rateComments, ...action.data },
      };
    case ACTION_TYPES.SET_REVIEWS:
      return { ...state, reviews: { ...action.data } };
    case ACTION_TYPES.SET_REVIEW:
      return { ...state, review: { ...action.data } };
    case ACTION_TYPES.SET_REVIEW_LABEL:
      return { ...state, reviewLabel: action.data };
    case ACTION_TYPES.SET_REPORTS:
      return { ...state, reports: { ...state.reports, ...action.data } };
    case ACTION_TYPES.SET_LABEL_REPORTS:
      return { ...state, reports: { ...state.reports, labels: action.data } };
    case ACTION_TYPES.SET_V2REPORTS:
      return { ...state, v2Report: { ...state.v2Report, ...action.data } };
    case ACTION_TYPES.SET_RATE_COMMENT:
      return {
        ...state,
        rateComment: { ...state.rateComment, ...action.data },
      };
    default:
      return state;
  }
});
