import React from "react";
import { Product } from "../types/store";

export const Columns = ({
  handleEdit,
  allProducts,
}: {
  handleEdit: any;
  allProducts: Product[];
}) => {
  return [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Tên nhãn",
      dataIndex: "name",
      key: "name",
      render: (name: any) => {
        return <>{name}</>;
      },
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      render: (level: any) => {
        return <>{level}</>;
      },
    },
    {
      title: "Sản phẩm",
      dataIndex: "products",
      key: "products",
      render: (products: string) => {
        const dataProducts = products ? products.split(",") : [];
        return (
          <>
            {dataProducts.map((p) => {
              return (
                <>
                  <span className="product-name">
                    {allProducts.find((product) => (product.id === p))?.name}
                  </span>
                  <span> </span>
                </>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id: string, record: any) => {
        return <span onClick={handleEdit(record)}>Sửa</span>;
      },
    },
  ];
};
