import { Api } from "modules/base";
import { URL_API } from "modules/common";
import { TGetLabelReportsParams, TGetOptionsParams, TGetRateCommentParams, TGetRateCommentsParams, TGetReviewLabelParams, TGetReviewParams, TGetReviewsParams, TGetV3ReportsParams, TPostCommentParams, TPostRateCommentParams, TPostReviewLabelParams } from "../types/requests";

export const apiGetReviews = (params: TGetReviewsParams): Promise<any> => {
  return Api.get(`${URL_API}/v3/reviews`, params);
};

export const apiGetReview = (params: TGetReviewParams): Promise<any> => {
  return Api.get(`${URL_API}/reviews/${params.review_id}`, {});
};

export const apiPostComment = (params: TPostCommentParams): Promise<any> => {
  return Api.post(`${URL_API}/v2/reviews/reply/${params.id}`, params);
};

export const apiGetOptions = (params: TGetOptionsParams): Promise<any> => {
  return Api.get(`${URL_API}/reviews/options`, params);
};

export const apiPostReviewLabel = (params: TPostReviewLabelParams): Promise<any> => {
  return Api.put(`${URL_API}/v2/reviews/${params.id}/labels`, params);
};

export const apiGetReviewLabel = (params: TGetReviewLabelParams): Promise<any> => {
  return Api.get(`${URL_API}/v2/reviews/${params.review_id}/labels`, {});
};

export const apiGetReports = (): Promise<any> => {
  return Api.get(`${URL_API}/v2/reviews/reports`, {});
};

export const apiGetV3Reports = (params: TGetV3ReportsParams): Promise<any> => {
  return Api.get(`${URL_API}/v3/reviews/reports`, params);
};

export const apiGetLabelReports = (params: TGetLabelReportsParams): Promise<any> => {
  return Api.get(`${URL_API}/v2/reviews/reports/label`, params);
};

export const apiGetRateComment = (params: TGetRateCommentParams): Promise<any> => {
  return Api.get(`${URL_API}/v3/reviews/rate-comments/${params.review_id}`, {});
};

export const apiGetRateComments = (params: TGetRateCommentsParams): Promise<any> => {
  return Api.get(`${URL_API}/v3/reviews/rate-comments`, params);
};

export const apiPostRateComment = (params: TPostRateCommentParams): Promise<any> => {
  return Api.post(`${URL_API}/v3/reviews/rate-comments`, params);
};
