import { ACTION_TYPES } from "./actionTypes";

export const getTailieuCaptcha = (id: string) => {
  return {
    type: ACTION_TYPES.GET_TAILIEU_CAPTCHA,
    id,
  };
};

export const setTailieuData = (data: any) => {
  return {
    type: ACTION_TYPES.SET_TAILIEU_DATA,
    data,
  };
};

export const getTailieuFile = (captcha: string) => {
  return {
    type: ACTION_TYPES.GET_TAILIEU_FILE,
    captcha,
  };
};
