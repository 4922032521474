import React from "react";

export const RateCommentsColumns = () => {
  return [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      with: "5%",
    },
    {
      title: "Review Text",
      dataIndex: "originalText",
      key: "originalText",
      render: (originalText: string) => {
        return <>{originalText}</>;
      },
    },
    {
      title: "Reply Text",
      dataIndex: "replyText",
      key: "replyText",
      render: (replyText: string) => {
        return <>{replyText}</>;
      },
    },
    {
      title: "Reply By",
      dataIndex: "replyBy",
      key: "replyBy",
      render: (replyBy: string) => {
        return <>{replyBy}</>;
      },
    },
    {
      title: "Like",
      dataIndex: "good",
      key: "good",
      with: "5%",
    },
    {
      title: "Dislike",
      dataIndex: "bad",
      key: "bad",
      with: "5%",
    },
    {
      title: "Action",
      dataIndex: "review_id",
      key: "review_id",
      width: "5%",
      render: (review_id: string) => {
        return (
          <a target={"_blank"} href={`reviews/${review_id}`} rel="noreferrer">
            <img
              src="https://cdn-icons-png.flaticon.com/512/4321/4321132.png"
              style={{ width: "50px" }}
              alt=""
            />
          </a>
        );
      },
    },
  ];
};
