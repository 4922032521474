import { PERMISIONS, SYSTEMS } from "modules/base/utils/constants";

export const getPermisions = (role: string | undefined) => {
    switch (role) {
        case "SUPER_ADMIN":
            return PERMISIONS;
        case "YO_ADMIN":
            return PERMISIONS.filter((p) => p.system === SYSTEMS.YO);
        case "KES_ADMIN":
            return PERMISIONS.filter((p) => p.system === SYSTEMS.KES);
        default:
            return [];
    }
};