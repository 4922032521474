import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { crawlPdf, updateCrawlPdf } from "../redux/actions";
import "../assets/scss/reports.scss";
import { TPdfCrawl } from "../types/pdfStore";

const Reports = () => {
  const dispatch = useDispatch();
  const [c, sC] = useState("");

  const { pdfCrawls, fileCrawl } = useSelector(
    (state: {
      pdfs: {
        pdfCrawls: TPdfCrawl[];
        fileCrawl: string;
      };
    }) => state.pdfs
  );

  useEffect(() => {
    document.title = pdfCrawls.length.toString();
    document.getElementById("anhlh")?.focus();
  }, [pdfCrawls]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(crawlPdf());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const pdfCrawl: TPdfCrawl | undefined = pdfCrawls.find(
    (p) => p.isGettingFile
  );

  useEffect(() => {
    if (fileCrawl) {
      document.getElementById("anhlh")?.focus();
      var ifrm = document.createElement("iframe");
      ifrm.setAttribute("src", fileCrawl);
      ifrm.style.display = "none";
      ifrm.style.width = "0";
      ifrm.style.height = "0";
      document.body.appendChild(ifrm);
    }
  }, [fileCrawl]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCaptchaChange = (e: any) => {
    sC(e.target.value);
  };

  const handleCaptchaPress = (e: any) => {
    if (e.charCode === 13) {
      if (pdfCrawl) {
        dispatch(updateCrawlPdf({ captcha: c, id: pdfCrawl.q.id }));
        sC("");
      }
    }
  };

  return (
    <div id="pdf-reports">
      {pdfCrawl && (
        <div className="download-pdf" key={pdfCrawl.q.id}>
          <p>
            Need:
            {pdfCrawls.filter((p) => p.isGettingFile && p.file === "").length}
          </p>
          <p>Tài khoản: {pdfCrawl.account}</p>
          <p>Ngày: {pdfCrawl.q.date}</p>
          <p>Id file: {pdfCrawl.q.id}</p>
          <img key={pdfCrawl.captchaUrl} src={pdfCrawl.captchaUrl} alt="" />
          <br />
          <br />
          <input
            value={c}
            id="anhlh"
            type="text"
            onChange={handleCaptchaChange}
            onKeyPress={handleCaptchaPress}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(Reports);
