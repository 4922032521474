import { Dispatch, Store } from "redux";
import { ACTION_TYPES } from "./actionTypes";
import { MiddlewareRegistry } from "modules/base";
import {
  apiGetCaptchaSigninUrl,
  apiGetCaptchaUrl,
  apiGetFileUrl,
  apiSigninTailieu,
} from "./services";
import {
  crawlPdf,
  getCaptchaSignin,
  removeCrawlPdf,
  setCaptchaSignin,
  setCrawlPdfs,
  setFileCrawl,
} from "./actions";
import { URL_API } from "modules/common";
import { TPdfCrawl } from "../types/pdfStore";

const FILE_TYPES: any[] = [
  "pdf",
  "doc",
  "docx",
  "ppt",
  "pptx",
  "xlsx",
  "xls",
  "rtf",
];

export const middleware =
  ({ dispatch, getState }: Store) =>
  (next: Function) =>
  async (action: any) => {
    next(action);
    switch (action.type) {
      case ACTION_TYPES.ADD_PDF_CRAWL: {
        return await handleAddPdfCrawl(dispatch, getState);
      }
      case ACTION_TYPES.UPDATE_PDF_CRAWL: {
        return await handleUpdatePdfCrawl(dispatch, getState, action.params);
      }
      case ACTION_TYPES.GET_CAPTCHA_SINGN: {
        apiGetCaptchaSigninUrl({}).then((rs) => {
          dispatch(setCaptchaSignin(rs));
        });
        return;
      }
      case ACTION_TYPES.SIGNIN_TAILIEU: {
        apiSigninTailieu(action.params).then((rs) => {
          dispatch(getCaptchaSignin({}));
        });
        return;
      }
    }
  };

MiddlewareRegistry.register(middleware);

const handleAddPdfCrawl = async (
  dispatch: Dispatch,
  getState: () => { pdfs: { pdfCrawls: TPdfCrawl[] } }
) => {
  const { pdfCrawls } = getState().pdfs;
  if (pdfCrawls.filter((p) => p.file === "").length < 10) {
    apiGetCaptchaUrl({}).then((rs) => {
      if (rs.status) {
        dispatch(setCrawlPdfs({ ...rs, file: "", isGettingFile: true }));
      }
      dispatch(crawlPdf());
    });
  } else {
    setTimeout(() => {
      dispatch(crawlPdf());
    }, 500);
  }
};

const handleUpdatePdfCrawl = async (
  dispatch: Dispatch,
  getState: () => { pdfs: { pdfCrawls: TPdfCrawl[] } },
  params: any
) => {
  const { pdfCrawls } = getState().pdfs;
  const pdfCrawl: TPdfCrawl | undefined = pdfCrawls.find(
    (p) => p.q.id === params.id
  );
  try {
    if (pdfCrawl) {
      dispatch(removeCrawlPdf(params.id));
      const filetypeData = pdfCrawl.q.fileName.split(".");
      const filetype = filetypeData[filetypeData.length - 1];
      if (FILE_TYPES.findIndex((f) => f === filetype) !== -1) {
        let url = `${URL_API}/v2/pdfs/get-tailieu-file?`;

        for (const [key, value] of Object.entries({
          ...params,
          ...pdfCrawl.q,
        })) {
          url = `${url}${key}=${value}&`;
        }
        dispatch(setFileCrawl(url));
      } else {
        apiGetFileUrl({ ...params, ...pdfCrawl.q }).then((rs) => {
          if (rs?.status === true) {
            dispatch(setFileCrawl(rs.file));
          }
        });
      }
    }
  } catch (error) {}
};
