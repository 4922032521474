import { Dispatch, Store } from "redux";
import { ACTION_TYPES } from "./actionTypes";
import { MiddlewareRegistry, TActionRequest, setAuthToken } from "modules/base";
import { apiLogin } from "./services";
import { setUserInfo } from "./actions";
import { TLoginResponses } from "../types/responses";
import { TActionParamSubmitLogin } from "../types/actions";
import { convertLoginResponsetoAuthStore } from "./functions";

export const middleware =
  ({ dispatch }: Store) =>
    (next: Function) =>
      async (action: TActionRequest<any>) => {
        next(action);
        switch (action.type) {
          case ACTION_TYPES.LOGOUT: {
            setAuthToken("");
            dispatch(
              setUserInfo({
                token: null,
              })
            );
            return;
          }
          case ACTION_TYPES.GET_USER_INFO: {
            apiLogin({ username: action.params, password: "0" })
              .then((rsLogin: TLoginResponses) => {
                setAuthToken(rsLogin.access_token);
                dispatch(
                  setUserInfo({
                    token: rsLogin.access_token,
                    auth: convertLoginResponsetoAuthStore(rsLogin),
                  })
                );
              })
              .catch((err) => {
                alert("Vui lòng liên hệ admin!");
              });
            return;
          }
          case ACTION_TYPES.SUBMIT_LOGIN: {
            return await handleSubmitLogin(dispatch, action.params);
          }
        }
      };

MiddlewareRegistry.register(middleware);

const handleSubmitLogin = async (dispatch: Dispatch, params: TActionParamSubmitLogin) => {
  apiLogin({ username: params.u, password: params.p })
    .then((rsLogin: TLoginResponses) => {
      setAuthToken(rsLogin.access_token);
      dispatch(
        setUserInfo({
          token: rsLogin.access_token,
          auth: convertLoginResponsetoAuthStore(rsLogin),
        })
      );
    })
    .catch((err) => {
      alert("Vui lòng liên hệ admin!");
    });
}