import { Store, Dispatch } from "redux";
import { ACTION_TYPES } from "./actionTypes";
import { MiddlewareRegistry } from "modules/base";
import {
  apiGetReviews,
  apiGetReview,
  apiPostComment,
  apiGetOptions,
  apiPostReviewLabel,
  apiGetReviewLabel,
  apiGetReports,
  apiGetLabelReports,
  apiGetV3Reports,
  apiGetRateComment,
  apiPostRateComment,
  apiGetRateComments,
} from "./services";
import { toastNotify } from "modules/common";
import { array2str } from "../utils/arrayFunc";
import {
  setOptions,
  setReviews,
  setReview,
  setReviewLabel,
  setReports,
  setLabelReports,
  setV2Reports,
  setRateComment,
  setRateComments,
} from "./actions";
import { ReviewStoreType } from "../types/reviewStore";
import { RATE_COMMENT } from "../utils/constants";
import { TGetLabelReportsParams, TGetOptionsParams, TGetRateCommentParams, TGetRateCommentsParams, TGetReviewLabelParams, TGetReviewParams, TGetV3ReportsParams, TPostRateCommentParams, TPostReviewLabelParams } from "../types/requests";
import { authStore } from "modules/auth/types/redux";

export const middleware =
  ({ dispatch, getState }: Store) =>
    (next: Function) =>
      async (action: any) => {
        next(action);
        switch (action.type) {
          case ACTION_TYPES.RATE_COMMENT: {
            await handleRateComment(dispatch, action.params, getState);
            return;
          }
          case ACTION_TYPES.GET_RATE_COMMENT: {
            await handleGetRateComment(dispatch, action.params);
            return;
          }
          case ACTION_TYPES.GET_OPTIONS: {
            await handleGetOptions(dispatch, action.params);
            return;
          }
          case ACTION_TYPES.REPLY_COMMENT: {
            await handleReplyComment(action.data, dispatch, getState);
            return;
          }
          case ACTION_TYPES.GET_REVIEW: {
            await handleGetReview(action.data, dispatch);
            return;
          }
          case ACTION_TYPES.GET_REVIEWS: {
            await handleGetReviews(action, dispatch);
            return;
          }
          case ACTION_TYPES.UPDATE_REVIEW_LABEL: {
            await handleUpdateReviewLabel(action.data, dispatch, getState);
            return;
          }
          case ACTION_TYPES.GET_REVIEW_LABEL: {
            await handleGetReviewLabel(action.params, dispatch);
            return;
          }
          case ACTION_TYPES.GET_REPORTS: {
            await handleGetReports(dispatch);
            return;
          }
          case ACTION_TYPES.GET_LABEL_REPORTS: {
            await handleGetLabelReports(action.params, dispatch);
            return;
          }
          case ACTION_TYPES.GET_V2REPORTS: {
            await handleGetV2Reports(action.params, dispatch);
            return;
          }
          case ACTION_TYPES.GET_RATE_COMMENTS: {
            await handleGetRateComments(action.params, dispatch);
            return;
          }
        }
      };

const handleGetRateComments = async (
  params: TGetRateCommentsParams,
  dispatch: Dispatch
) => {
  dispatch(setRateComments({ loading: true }));
  apiGetRateComments(params).then((rs) => {
    dispatch(setRateComments({ ...rs, loading: false }));
  });
};

const handleRateComment = async (
  dispatch: Dispatch,
  params: TPostRateCommentParams,
  getState: () => { reviews: ReviewStoreType }
) => {
  const { rateComment } = getState().reviews;
  if (!rateComment.isRating) {
    dispatch(
      setRateComment({
        rate: params.rate,
        comment_id: params.comment_id,
        isRating: true,
      })
    );
    apiPostRateComment(params).then((rs) => {
      if (rs.status) {
        const like =
          params.rate === RATE_COMMENT.NA
            ? rateComment.rate === RATE_COMMENT.GOOD
              ? rateComment.like - 1
              : rateComment.like
            : params.rate === RATE_COMMENT.BAD
              ? rateComment.rate === RATE_COMMENT.GOOD
                ? rateComment.like - 1
                : rateComment.like
              : rateComment.like + 1;

        const dislike =
          params.rate === RATE_COMMENT.NA
            ? rateComment.rate === RATE_COMMENT.BAD
              ? rateComment.dislike - 1
              : rateComment.dislike
            : params.rate === RATE_COMMENT.GOOD
              ? rateComment.rate === RATE_COMMENT.BAD
                ? rateComment.dislike - 1
                : rateComment.dislike
              : rateComment.rate === RATE_COMMENT.BAD
                ? rateComment.dislike
                : rateComment.dislike + 1;
        dispatch(
          setRateComment({
            rate: params.rate,
            comment_id: params.comment_id,
            isRating: false,
            dislike,
            like,
          })
        );
      } else {
        dispatch(
          setRateComment({
            rate: params.rate,
            comment_id: params.comment_id,
            isRating: false,
          })
        );
      }
    });
  }
  return;
};

const handleGetRateComment = async (
  dispatch: Dispatch,
  params: TGetRateCommentParams
) => {
  apiGetRateComment(params).then((rs) => {
    if (rs.status) {
      dispatch(
        setRateComment({
          review_id: params.review_id,
          rate: rs.rate,
          comment_id: rs.comment_id,
          like: parseInt(rs.like),
          dislike: parseInt(rs.dislike),
        })
      );
    }
  });
};

const handleGetV2Reports = async (
  params: TGetV3ReportsParams,
  dispatch: Dispatch
) => {
  dispatch(
    setV2Reports({
      labelOfReviews: [],
      createByOfComments: [],
      rateOfReviews: [],
      statusOfReviews: [],
      createByOfLabelReviews: [],
      labelOfReviewByDates: [],
      rateOfReviewByDates: [],
      statusOfReviewByDates: [],
    })
  );
  apiGetV3Reports(params).then((rs) => {
    dispatch(setV2Reports(rs));
  });
};

const handleGetOptions = async (dispatch: Dispatch, params: TGetOptionsParams) => {
  apiGetOptions(params).then((rs: any) => {
    dispatch(setOptions(rs));
  });
};

const handleReplyComment = async (
  data: any,
  dispatch: Dispatch,
  getState: () => {
    reviews: ReviewStoreType, auth: authStore
  }
) => {
  const { reviews, review } = getState().reviews;
  const { auth } = getState().auth;
  let dateNow = new Date();
  dispatch(
    setReviews({
      ...reviews,
      data: reviews.data.map((d: any) =>
        d.id === data.id
          ? {
            ...d,
            createdBy: auth?.email,
            uText: data.text,
            uLastModified: dateNow.getTime() / 1000,
          }
          : d
      ),
    })
  );
  try {
    dispatch(
      setReview({
        ...review,
        comments:
          review.comments.length === 1
            ? [
              ...review.comments,
              {
                type: 1,
                text: data.text,
                createdBy: auth?.email,
                lastModified: dateNow.getTime() / 1000,
              },
            ]
            : review.comments.map((c: any) =>
              c.type === 1
                ? {
                  ...c,
                  text: data.text,
                  lastModified: dateNow.getTime() / 1000,
                }
                : c
            ),
      })
    );
  } catch (error) { }
  apiPostComment(data).then((rs: any) => { });
};

const handleGetReview = async (params: TGetReviewParams, dispatch: Dispatch) => {
  dispatch(setReview({ loading: true }));
  apiGetReview(params)
    .then((rs) => {
      dispatch(setReview({ ...rs, loading: false }));
    })
    .catch((err) => { });
};

const handleGetReviews = async (action: any, dispatch: Dispatch) => {
  dispatch(
    setReviews({
      data: [],
      page: action.data,
      loading: true,
    })
  );
  apiGetReviews({
    page: action.data,
    size: action.size,
    textlike: action.q,
    rate: array2str(action.filters?.rate, 0),
    productId: action.filters.productId,
    replyby: array2str(action.filters?.replyBy, 1),
    versionname: array2str(action.filters?.appVersionName, 1),
    language: array2str(action.filters?.language, 1),
    labels: array2str(action.filters?.labels, 1),
    os: array2str(action.filters?.os, 1),
    isreplied: action.filters?.status
      ? action.filters?.status.length === 1
        ? action.filters?.status[0]
        : undefined
      : undefined,
  })
    .then((rs) => {
      dispatch(setReviews({ ...rs, loading: false }));
    })
    .catch((err) => { });
};

const handleUpdateReviewLabel = async (
  data: TPostReviewLabelParams,
  dispatch: Dispatch,
  getState: () => { reviews: ReviewStoreType }
) => {
  const { reviews } = getState().reviews;
  dispatch(
    setReviews({
      ...reviews,
      data: reviews.data.map((d: any) =>
        d.id === data.id
          ? {
            ...d,
            labels: array2str(
              data.labels.map((d: any) => d.id),
              0
            ),
          }
          : d
      ),
    })
  );
  apiPostReviewLabel(data)
    .then(() => {
      toastNotify("Gán nhãn thành công!", "info");
    })
    .catch(() => { });
};

const handleGetLabelReports = async (params: TGetLabelReportsParams, dispatch: Dispatch) => {
  apiGetLabelReports(params).then((rs) => {
    dispatch(setLabelReports(rs));
  });
};

const handleGetReports = async (dispatch: Dispatch) => {
  apiGetReports().then((rs) => {
    dispatch(setReports(rs));
  });
};

const handleGetReviewLabel = async (params: TGetReviewLabelParams, dispatch: Dispatch) => {
  dispatch(setReviewLabel([]));
  apiGetReviewLabel(params).then((rs) => {
    dispatch(setReviewLabel(rs.data));
  });
};

MiddlewareRegistry.register(middleware);
