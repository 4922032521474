import { ACTION_TYPES } from "./actionTypes";
import { TActionDataUserInfo, TActionParamSubmitLogin } from "../types/actions";

export const getUserInfo = (credential: string) => {
  return {
    type: ACTION_TYPES.GET_USER_INFO,
    params: credential,
  };
};

export const submitLogin = (params: TActionParamSubmitLogin) => {
  return {
    type: ACTION_TYPES.SUBMIT_LOGIN,
    params
  };
};

export const logout = () => {
  return {
    type: ACTION_TYPES.LOGOUT,
  };
};

export const setUserInfo = (data: TActionDataUserInfo) => {
  return {
    type: ACTION_TYPES.SET_USER_INFO,
    data,
  };
};
