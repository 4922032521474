import { ReducerRegistry, TAction } from "modules/base";
import { LabelStore } from "../types/store";
import { ACTION_TYPES } from "./actionTypes";

const initState: LabelStore = {
  labels: { data: [], total: 0, page: 1, loading: true },
  options: { products: [] },
};

ReducerRegistry.register("labels", (state = initState, action: TAction) => {
  switch (action.type) {
    case ACTION_TYPES.SET_LABELS:
      return { ...state, labels: { ...action.data } };
    case ACTION_TYPES.SET_OPTIONS:
      return { ...state, options: action.data };
    default:
      return state;
  }
});
