import { TGetV3ReportsParams, TPostRateCommentParams, TGetRateCommentParams, TGetReviewLabelParams } from "../types/requests";
import { RateCommentType } from "../types/reviewStore";
import { ACTION_TYPES } from "./actionTypes";

export const getReviews = (
  page: number,
  filters: any,
  size?: number,
  q?: any
) => {
  return {
    type: ACTION_TYPES.GET_REVIEWS,
    data: page,
    filters,
    size,
    q,
  };
};

export const setReviews = (data: any) => {
  return {
    type: ACTION_TYPES.SET_REVIEWS,
    data,
  };
};

export const getReview = (reviewId: string) => {
  return {
    type: ACTION_TYPES.GET_REVIEW,
    data: reviewId,
  };
};

export const setReview = (data: any) => {
  return {
    type: ACTION_TYPES.SET_REVIEW,
    data,
  };
};

export const replyComment = (data: any) => {
  return {
    type: ACTION_TYPES.REPLY_COMMENT,
    data,
  };
};

export const getOptions = (productId?: string) => {
  return {
    type: ACTION_TYPES.GET_OPTIONS,
    params: { productId },
  };
};

export const setOptions = (data: any) => {
  return {
    type: ACTION_TYPES.SET_OPTIONS,
    data,
  };
};

export const updateReviews = (data: any, reviewId: any) => {
  return {
    type: ACTION_TYPES.UPDATE_REVIEWS,
    data,
    reviewId,
  };
};

export const updateReviewLabel = (data: any) => {
  return {
    type: ACTION_TYPES.UPDATE_REVIEW_LABEL,
    data,
  };
};

export const getReviewLabel = (params: TGetReviewLabelParams) => {
  return {
    type: ACTION_TYPES.GET_REVIEW_LABEL,
    params
  };
};

export const setReviewLabel = (data: any) => {
  return {
    type: ACTION_TYPES.SET_REVIEW_LABEL,
    data,
  };
};

export const getReports = () => {
  return {
    type: ACTION_TYPES.GET_REPORTS,
  };
};

export const setReports = (data: any) => {
  return {
    type: ACTION_TYPES.SET_REPORTS,
    data,
  };
};

export const getLabelReports = (params: any) => {
  return {
    type: ACTION_TYPES.GET_LABEL_REPORTS,
    params,
  };
};

export const setLabelReports = (data: any) => {
  return {
    type: ACTION_TYPES.SET_LABEL_REPORTS,
    data,
  };
};

export const getV2Reports = (params: TGetV3ReportsParams) => {
  return {
    type: ACTION_TYPES.GET_V2REPORTS,
    params,
  };
};

export const setV2Reports = (data: any) => {
  return {
    type: ACTION_TYPES.SET_V2REPORTS,
    data,
  };
};

export const postRateComment = (params: TPostRateCommentParams) => {
  return {
    type: ACTION_TYPES.RATE_COMMENT,
    params,
  };
};

export const getRateComment = (params: TGetRateCommentParams) => {
  return {
    type: ACTION_TYPES.GET_RATE_COMMENT,
    params,
  };
};

export const setRateComment = (data: {
  review_id?: string;
  rate: number;
  comment_id?: string;
  like?: number;
  isRating?: boolean;
  dislike?: number;
}) => {
  return {
    type: ACTION_TYPES.SET_RATE_COMMENT,
    data,
  };
};

export const getRateComments = (params: { page: number; size: number }) => {
  return {
    type: ACTION_TYPES.GET_RATE_COMMENTS,
    params,
  };
};

export const setRateComments = (data: {
  data?: RateCommentType[];
  total?: number;
  page?: number;
  loading?: boolean;
}) => {
  return {
    type: ACTION_TYPES.SET_RATE_COMMENTS,
    data,
  };
};
