export const ACTION_TYPES = {
  GET_RATE_COMMENTS: Symbol("GET_REVIEWS"),
  SET_RATE_COMMENTS: Symbol("SET_REVIEWS"),
  GET_REVIEWS: Symbol("GET_REVIEWS"),
  SET_REVIEWS: Symbol("SET_REVIEWS"),
  UPDATE_REVIEWS: Symbol("UPDATE_REVIEWS"),
  GET_REVIEW: Symbol("GET_REVIEW"),
  SET_REVIEW: Symbol("SET_REVIEW"),
  REPLY_COMMENT: Symbol("REPLY_COMMENT"),
  GET_OPTIONS: Symbol("GET_OPTIONS"),
  SET_OPTIONS: Symbol("SET_OPTIONS"),
  UPDATE_REVIEW_LABEL: Symbol("UPDATE_REVIEW_LABEL"),
  GET_REVIEW_LABEL: Symbol("GET_REVIEW_LABEL"),
  SET_REVIEW_LABEL: Symbol("SET_REVIEW_LABEL"),
  GET_REPORTS: Symbol("GET_REPORTS"),
  SET_REPORTS: Symbol("SET_REPORTS"),
  GET_LABEL_REPORTS: Symbol("GET_LABEL_REPORTS"),
  SET_LABEL_REPORTS: Symbol("SET_LABEL_REPORTS"),
  GET_V2REPORTS: Symbol("GET_REPORTS"),
  SET_V2REPORTS: Symbol("SET_REPORTS"),
  GET_RATE_COMMENT: Symbol("GET_REPORTS"),
  SET_RATE_COMMENT: Symbol("SET_REPORTS"),
  RATE_COMMENT: Symbol("SET_REPORTS"),
};
