import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Table, Select, Input, Button } from "antd";
import {
  getUsers,
  updateUser,
  updatePermisionUser,
  addUser,
} from "../redux/actions";
import { ROLES } from "modules/base/utils/constants";
import "../assets/scss/usersWrap.scss";
import md5 from "md5";
import { array2str } from "modules/classes/utils/arrayFunc";
import { authStore } from "modules/auth/types/redux";
import { getPermisions } from "../redux/functions";
import { TUsersStore } from "../types/redux";

const UsersWrap = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const query: any = new URLSearchParams(location.search);

  const [userInfo, setUserInfo] = useState<{
    email: string;
    name: string;
    roles: string[];
    type: number;
  }>({
    email: "",
    name: "",
    roles: [],
    type: 0,
  });

  const { auth } = useSelector((state: { auth: authStore }) => state);
  const { users } = useSelector((state: {
    users: TUsersStore;
  }) => state.users
  );

  const [pagination, setPagination] = React.useState({
    page: 1,
    current: 1,
    total: users.total,
    pageSize: 200,
  });

  useEffect(() => {
    setPagination({
      ...pagination,
      total: users.total,
      current: parseInt(query.get("page") || 1),
      pageSize: parseInt(query.get("pageSize") || 200),
    });
  }, [users]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getUsers(query.get("page"), {}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange: any = (pagination: any, filters: any) => {
    history.push(
      `${location.pathname}?page=${pagination.current}&pageSize=${pagination?.pageSize || ""
      }&username=${filters.username}`
    );
    dispatch(getUsers(pagination.current, filters, pagination?.pageSize));
  };

  const filterUsers = (query.get("username") || "").split(",");



  const handleChangePermision = (_: { id: string; roles: string }) => () => {
    const roles: string[] = _.roles
      ? _.roles
        .split(",")
        .map(
          (r) =>
            getPermisions(
              auth.auth.permisions.find(p => p.role.includes("_ADMIN"))?.role
            ).find((p) => p.name === r)?.id!
        )
      : [];
    dispatch(updateUser({ is_enable: 1, roles }, _.id));
  };

  const handleUpdatePermisionUser =
    (_: { id: string }) => (roles: string[]) => {
      dispatch(
        updatePermisionUser(
          array2str(
            roles.map(
              (r) =>
                getPermisions(
                  auth.auth.permisions.find(p => p.role.includes("_ADMIN"))?.role
                ).find((p) => p.id === r)?.name
            ),
            0
          ),
          _.id
        )
      );
    };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      render: () => {
        return <>#</>;
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (id: string, _: any) => {
        return <>{_.username}</>;
      },
      filterSearch: true,
      filters: users.data
        .filter((u) => u.username)
        .map((t) => {
          return { ...t, text: t.username, value: t.username };
        }),
    },
    {
      title: "Password",
      dataIndex: "username",
      key: "username",
      render: (username: string) => {
        return <p>{md5(`snow${username}`)}</p>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Permisions",
      dataIndex: "roles",
      key: "roles",
      render: (roles: string, _: any) => {
        const userPermisions: string[] = roles
          ? roles
            .split(",")
            .filter((value, index, self) => {
              return self.indexOf(value) === index;
            })
            .map(
              (r) =>
                getPermisions(
                  auth.auth.permisions.find(p => p.role.includes("_ADMIN"))?.role
                ).find((p) => p.name === r)?.id!
            )
          : [];
        return (
          <>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              defaultValue={userPermisions}
              onChange={handleUpdatePermisionUser(_)}
              options={getPermisions(
                auth.auth.permisions.find(p => p.role.includes("_ADMIN"))?.role
              ).map((p) => ({
                ...p,
                label: p.name,
                value: p.id,
              }))}
            />
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any, _: { roles: string; id: string }) => {
        return <p onClick={handleChangePermision(_)}>Update</p>;
      },
    },
  ];

  const handleChangeUsername = (e: { target: { value: string } }) => {
    setUserInfo({ ...userInfo, email: e.target.value });
  };

  const handleChangeFullname = (e: { target: { value: string } }) => {
    setUserInfo({ ...userInfo, name: e.target.value });
  };

  const handleChangeRole = (e: number) => {
    setUserInfo({ ...userInfo, type: e });
  };

  const handleUpdatePermision = (e: string[]) => {
    setUserInfo({ ...userInfo, roles: e });
  };

  const resetUserInfo = () => {
    setUserInfo({
      email: "",
      name: "",
      roles: [],
      type: 0,
    });
  };

  const handleAddUser = () => {
    if (
      userInfo.name.trim() !== "" &&
      userInfo.email.trim() !== "" &&
      userInfo.type !== 0
    ) {
      dispatch(
        addUser(
          {
            is_enable: 1,
            roles: userInfo.roles,
            name: userInfo.name.trim(),
            email: userInfo.email.trim(),
            type: userInfo.type,
          },
          resetUserInfo
        )
      );
    } else {
      alert("Chưa nhập đủ thông tin!");
    }
  };

  return (
    <>
      <div id="users">
        {auth.auth.permisions.filter((p) => p.role === "KES_ADMIN")
          .length === 1 && (
            <div>
              <Input
                placeholder="Tên đăng nhập"
                onChange={handleChangeUsername}
                value={userInfo.email}
                style={{ width: "160px", marginBottom: "10px" }}
              />
              <span className="device" />
              <Input
                placeholder="Họ tên"
                onChange={handleChangeFullname}
                value={userInfo.name}
                style={{ width: "160px", marginBottom: "10px" }}
              />
              <span className="device" />
              <Select
                value={userInfo.type}
                placeholder="Chọn loại tài khoản"
                style={{ width: "260px", marginBottom: "10px" }}
                onChange={handleChangeRole}
                options={ROLES.map((r) => ({ ...r, value: r.id, label: r.name }))}
              />
              <Select
                mode="multiple"
                allowClear
                style={{ width: "260px", marginBottom: "10px" }}
                placeholder="Chọn quyền"
                defaultValue={[]}
                value={userInfo.roles}
                onChange={handleUpdatePermision}
                options={getPermisions(
                  auth.auth.permisions.find(p => p.role.includes("_ADMIN"))?.role
                ).map((p) => ({
                  ...p,
                  label: p.name,
                  value: p.id,
                }))}
              />
              <span className="device" />
              <Button type="primary" size="middle" onClick={handleAddUser}>
                Thêm tài khoản
              </Button>
            </div>
          )}

        <Table
          rowKey={"key"}
          dataSource={users.data
            .filter(
              (u) =>
                filterUsers.findIndex((user: string) => user === u.username) !==
                -1 ||
                (filterUsers.length === 1 && filterUsers[0] === "")
            )
            .filter(
              (u) =>
                u.username !== "" &&
                ((u.roles !== "" && u.roles) || u.username.indexOf("@") !== -1)
            )
            .map((u) => ({ ...u, key: u.id }))}
          columns={columns}
          loading={users.loading}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
};

export default UsersWrap;
