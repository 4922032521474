import { ReducerRegistry, TAction } from "modules/base";
import { TPdfCrawl } from "../types/pdfStore";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  signinTailieuData: "",
  pdfCrawls: [],
  fileCrawl: ""
};

ReducerRegistry.register("pdfs", (state = initState, action: TAction) => {
  switch (action.type) {
    case ACTION_TYPES.SET_CAPTCHA_SINGN:
      return { ...state, signinTailieuData: action.data };
    case ACTION_TYPES.SET_PDF_CRAWLS:
      return { ...state, pdfCrawls: [...state.pdfCrawls, action.data] };
    case ACTION_TYPES.REMOVE_PDF_CRAWL:
      return { ...state, pdfCrawls: state.pdfCrawls.filter((p: TPdfCrawl) => p.q.id !== action.data) };
    case ACTION_TYPES.SET_FILE_CRAWL:
      return { ...state, fileCrawl: action.data };
    default:
      return state;
  }
});
