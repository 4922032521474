import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { renderReportLabels } from "../redux/functions";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const colors: string[] = [
  "#63f542",
  "#3c8ee6",
  "#f0eb62",
  "#f5b356",
  "#f22222",
  "#00ff00",
  "#00ffff",
  "#a4c2f4",
  "#1155cc",
  "#ffff00",
  "#f6b26b",
  "#45818e",
  "#d5a6bd",
  "#ff9900",
  "#ff9900",
];

const ChartX = ({
  datas,
  labels,
  title,
  filter,
}: {
  datas: { date: string; name: string; total: number }[];
  labels: { name: string }[];
  title: string;
  filter: { f: number; t: number };
}) => {
  const options = {
    animationEnabled: true,
    title: {
      text: title,
    },
    legend: {
      verticalAlign: "center",
      horizontalAlign: "right",
      reversed: true,
      cursor: "pointer",
      fontSize: 16,
    },
    toolTip: {
      shared: true,
    },
    data: labels
      .map((l) => l.name)
      .map((label, index) => {
        return {
          type: "stackedColumn100",
          name: label,
          showInLegend: true,
          color: colors[index],
          dataPoints: renderReportLabels(filter.f, filter.t).map((d) => {
            return {
              label: d,
              y: datas
                .filter((data) => data.date === d && data.name === label)
                .reduce((t: number, d) => {
                  return t + d.total;
                }, 0),
            };
          }),
        };
      }),
  };

  return (
    <>
      <CanvasJSChart options={options} />
    </>
  );
};

export default ChartX;
