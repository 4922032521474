import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { secondtoHour } from "../utils/date";
import { Select } from "antd";
import moment from "moment";
import { DAY_OF_WEEKS } from "../utils/constants";
import { ClassesStoreType } from "../types/storeType";
import {
  getClassAttendance,
  postClassAttendance,
  setClassAttendance,
} from "../redux/actions";

export const ReportClassColumns = () => {
  const dispatch = useDispatch();

  const { subjects, curricolums, rates, v2Students, v2Teachers } = useSelector(
    (state: { classes: ClassesStoreType }) => state.classes.options
  );

  const { classAttendance } = useSelector(
    (state: { classes: ClassesStoreType }) => state.classes
  );

  const renderArray = (idList: any, students: any) => {
    try {
      const data = idList.split(",").map((s: any) => s);
      return students.filter(
        (s: any) => data.findIndex((d: any) => d === s.value) !== -1
      );
    } catch (error) {
      return [];
    }
  };

  const handleChangeClassAttendance = (class_id: string, value: string[]) => {
    dispatch(
      setClassAttendance({
        class_id,
        data: value.map((v) => ({ student_id: v, status: 1 })),
      })
    );
  };

  const handleClickClassAttendance = (class_id: string) => () => {
    dispatch(getClassAttendance({ class_id }));
  };

  const handleBlurClassAttendance = (class_id: string) => () => {
    dispatch(postClassAttendance(classAttendance));
  };

  return [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      width: "5%",
    },

    {
      title: "Thứ",
      dataIndex: "class_day",
      key: "class_day",
      width: "8%",
      render: (class_day: any) => {
        return <>{DAY_OF_WEEKS[moment(class_day).day()]}</>;
      },
    },
    {
      title: "Ngày",
      dataIndex: "class_day",
      key: "class_day",
      width: "8%",
      render: (class_day: any) => {
        return <>{class_day}</>;
      },
    },
    {
      title: "Giáo viên",
      dataIndex: "teacher_id",
      width: "10%",
      key: "teacher_id",
      render: (teacher_id: any) => {
        return (
          <>{v2Teachers.find((t: any) => t.value === teacher_id)?.label}</>
        );
      },
      filterSearch: true,
      filters: v2Teachers.map((t: any) => {
        return { ...t, text: t.label };
      }),
    },
    {
      title: "Loại lớp",
      dataIndex: "curiculum_id",
      key: "curiculum_id",
      width: "10%",
      render: (curiculum_id: any) => {
        return (
          <>{curricolums.find((c: any) => c.value === curiculum_id)?.label}</>
        );
      },
    },
    {
      title: "Môn học",
      dataIndex: "subject_id",
      width: "10%",
      key: "subject_id",
      render: (subject_id: any) => {
        return <>{subjects.find((s: any) => s.value === subject_id)?.label}</>;
      },
    },
    {
      title: "Danh sách học sinh",
      dataIndex: "students",
      key: "students",
      render: (students: any, _: any) => {
        return (
          <>
            <Select
              mode="multiple"
              style={{ width: "200px" }}
              value={
                classAttendance.class_id === _.id
                  ? classAttendance.data
                    .filter((d) => d.status === 1)
                    .map((d) => d.student_id)
                  : []
              }
              options={renderArray(students, v2Students)}
              onChange={(value) => handleChangeClassAttendance(_.id, value)}
              onClick={handleClickClassAttendance(_.id)}
              onBlur={handleBlurClassAttendance(_.id)}
              maxTagCount="responsive"
            />
          </>
        );
      },
    },
    {
      title: "Payrate",
      width: "10%",
      dataIndex: "start_time",
      key: "start_time",
      render: (start_time: any, data: any) => {
        const students = data?.students ? data?.students.split(",") : [];
        const rate = rates
          ? rates.find(
            (r: any) =>
              r.curricolum_id === data.curiculum_id &&
              r.class_scope === students.length
          )?.rate || 0
          : 0;
        return <>{`${rate}k/h`}</>;
      },
    },
    {
      title: "Hour",
      dataIndex: "end_time",
      width: "10%",
      key: "end_time",
      render: (end_time: any, data: any) => {
        return <>{secondtoHour(end_time - data.start_time)}</>;
      },
    },
    {
      title: "Tiền",
      dataIndex: "end_time",
      width: "10%",
      key: "end_time",
      render: (end_time: any, data: any) => {
        const students = data?.students ? data?.students.split(",") : [];
        const rate = rates
          ? rates.find(
            (r: any) =>
              r.curricolum_id === data.curiculum_id &&
              r.class_scope === students.length
          )?.rate || 0
          : 0;
        const bonus = rates
          ? rates.find(
            (r: any) =>
              r.curricolum_id === data.curiculum_id &&
              r.class_scope === students.length
          )?.bonus || 0
          : 0;
        return (
          <>
            {rate *
              (data.curiculum_id === "6"
                ? 1
                : secondtoHour(end_time - data.start_time)) +
              bonus +
              "k"}
          </>
        );
      },
    },
  ];
};
