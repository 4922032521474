import RouteRegistry from "../base/redux/RouteRegistry";
import {
  ReportPage,
  ReviewPage,
  ReviewsPage,
  V2ReportPage,
  RateCommentsPage,
} from "./pages";

const routes = [
  {
    path: "/reviews/:id",
    noAuthen: false,
    permision: "YO_REVIEW",
    component: ReviewPage,
  },
  {
    path: "/duetcat/reviews",
    noAuthen: false,
    permision: "YO_REVIEW",
    component: ReviewsPage,
  },
  {
    path: "/duettiles/reviews",
    noAuthen: false,
    permision: "YO_REVIEW",
    component: ReviewsPage,
  },
  {
    path: "/reviews",
    noAuthen: false,
    permision: "YO_REVIEW",
    component: ReviewsPage,
  },
  {
    path: "/rate-comments",
    noAuthen: false,
    permision: "YO_REVIEW",
    component: RateCommentsPage,
  },
  {
    path: "/reports",
    noAuthen: false,
    permision: "YO_REVIEW",
    component: ReportPage,
  },
  {
    path: "/duetcat/v2-reports",
    noAuthen: false,
    permision: "YO_REVIEW",
    component: V2ReportPage,
  },
  {
    path: "/duettiles/v2-reports",
    noAuthen: false,
    permision: "YO_REVIEW",
    component: V2ReportPage,
  },
  {
    path: "/v2-reports",
    noAuthen: false,
    permision: "YO_REVIEW",
    component: V2ReportPage,
  },
];

RouteRegistry.register(routes);
