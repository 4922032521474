import React, { useState } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ToolStore } from "../types/store";
import { getTailieuCaptcha, getTailieuFile } from "../redux/actions";

const Tools = () => {
  const dispatch = useDispatch();
  const [captcha, setCaptcha] = useState<string>("");
  const [urlTailieu, setUrlTailieu] = useState<string>("");

  const { tailieu } = useSelector((state: { tools: ToolStore }) => state.tools);

  const handleUrlTailieuChange = (e: { target: { value: string } }) => {
    setUrlTailieu(e.target.value);
  };

  const handleUrlTailieuKeyPress = (e: { charCode: number }) => {
    if (e.charCode === 13) {
      handleGetCaptchaUrl();
    }
  };

  const handleGetCaptchaUrl = () => {
    if (urlTailieu.indexOf("tailieu.vn") !== -1) {
      try {
        const temp = urlTailieu.split(".html")[0];
        if (temp.length === 1) {
          alert(
            "Đường dẫn chưa đúng định dạng! Định dang đúng https://tailieu.vn/***-id.html?***"
          );
        } else {
          const temp1 = temp.split("-");
          if (temp1.length === 1) {
            alert(
              "Đường dẫn chưa đúng định dạng! Định dang đúng https://tailieu.vn/***-id.html?***"
            );
          } else {
            const id = temp1[temp1.length - 1];
            dispatch(getTailieuCaptcha(id));
          }
        }
      } catch (error) {
        alert(
          "Đường dẫn chưa đúng định dạng! Định dang đúng https://tailieu.vn/***-id.html?***"
        );
      }
    } else {
      alert(
        "Đường dẫn chưa đúng định dạng! Định dang đúng https://tailieu.vn/***-id.html?***"
      );
    }
  };

  const handleCaptchaChange = (e: { target: { value: string } }) => {
    setCaptcha(e.target.value);
  };

  const handleCaptchaKeyPress = (e: { charCode: number }) => {
    if (e.charCode === 13) {
      handleGetFile();
      setCaptcha("");
    }
  };

  const handleGetFile = () => {
    if (captcha.trim() !== "") {
      dispatch(getTailieuFile(captcha));
      setCaptcha("");
    }
  };

  return (
    <div id="tools">
      <div>
        <h3>Bước 1: Nhập link cần tải</h3>
        <input
          placeholder="Link tailieu.vn"
          type="text"
          style={{ width: "300px" }}
          onChange={handleUrlTailieuChange}
          onKeyPress={handleUrlTailieuKeyPress}
        />
        <span> </span>
        <Button type="primary" onClick={handleGetCaptchaUrl}>
          {tailieu.isCallingCaptcha
            ? "Đang lấy thông tin file"
            : "Lấy thông tin file"}
        </Button>
        <br />
        <br />
        <br />
        <h3>Bước 2: Nhập captcha và tải file</h3>
        <img src={tailieu.captchaUrl} alt="Ảnh captcha hiển thị tại đây" />
        <br />
        <input
          placeholder="Nhập mã captcha"
          type="text"
          style={{ width: "300px" }}
          onChange={handleCaptchaChange}
          onKeyPress={handleCaptchaKeyPress}
        />
        <span> </span>
        <Button type="primary" onClick={handleGetFile}>
          {tailieu.isGettingFile ? "Đang tải file" : "Tải file"}
        </Button>
        <br />
        {tailieu.fileUrl !== "" && (
          <iframe
            src={tailieu.fileUrl}
            title="W3Schools Free Online Web Tutorials"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default Tools;
