import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ChartY = ({
  datas,
  title,
}: {
  datas: { x: number; y: number }[];
  title: string;
}) => {
  const options = {
    theme: "light2",
    title: {
      text: title,
    },
    data: [
      {
        type: "line",
        xValueFormatString: "DD/MM/YYYY",
        yValueFormatString: "#,##0.00",
        dataPoints: datas.map((d) => ({
          x: new Date(d.x),
          y: d.y,
          indexLabel: (d.y + "").substring(0, 5),
        })),
      },
    ],
  };
  return (
    <>
      <CanvasJSChart options={options} />
    </>
  );
};

export default ChartY;
