import RouteRegistry from "../base/redux/RouteRegistry";
import { ToolsPage } from "./pages";

const routes = [
  {
    path: "/tools",
    noAuthen: false,
    permision: "SUPER_ADMIN",
    component: ToolsPage,
  },
];

RouteRegistry.register(routes);
