import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilDrop,
  cilPencil,
  cilSpeedometer,
  cilActionRedo,
} from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavTitle,
    name: "DUET CAT",
    permision: "YO_REVIEW",
  },
  {
    component: CNavItem,
    name: "Good/Bad Comment",
    to: "/rate-comments",
    icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
    permision: "YO_ADMIN",
  },
  {
    component: CNavItem,
    name: "Danh sách review",
    to: "/duetcat/reviews?productId=d8851d41-dd73-40f2-907c-28234155953c",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    permision: "YO_REVIEW",
  },
  {
    component: CNavItem,
    name: "Báo cáo V2",
    to: "/duetcat/v2-reports?productId=d8851d41-dd73-40f2-907c-28234155953c",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    permision: "YO_REVIEW",
  },
  {
    component: CNavTitle,
    name: "DUET TILES",
    permision: "YO_REVIEW",
  },
  {
    component: CNavItem,
    name: "Good/Bad Comment",
    to: "/rate-comments",
    icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
    permision: "YO_ADMIN",
  },
  {
    component: CNavItem,
    name: "Danh sách review",
    to: "/duettiles/reviews?productId=e65401b7-c978-4b4e-8cfd-c0771918c461",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    permision: "YO_REVIEW",
  },
  {
    component: CNavItem,
    name: "Báo cáo V2",
    to: "/duettiles/v2-reports?productId=e65401b7-c978-4b4e-8cfd-c0771918c461",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    permision: "YO_REVIEW",
  },
  {
    component: CNavTitle,
    name: "Tài khoản",
    permision: "SUPER_ADMIN",
  },
  {
    component: CNavTitle,
    name: "Tài khoản",
    permision: "YO_ADMIN",
  },
  {
    component: CNavItem,
    name: "Danh sách tài khoản",
    to: "/kes-users",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    permision: "KES_ACCOUNT",
  },
  {
    component: CNavItem,
    name: "Danh sách tài khoản",
    to: "/yo-users",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    permision: "YO_ADMIN",
  },
  {
    component: CNavItem,
    name: "Danh sách tài khoản",
    to: "/users",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    permision: "SUPER_ADMIN",
  },
  {
    component: CNavItem,
    name: "Danh sách nhãn",
    to: "/labels",
    icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
    permision: "SUPER_ADMIN",
  },
  {
    component: CNavItem,
    name: "Danh sách nhãn",
    to: "/yo-labels",
    icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
    permision: "YO_ADMIN",
  },
  {
    component: CNavTitle,
    name: "KES",
    permision: "KES_ADMIN",
  },
  {
    component: CNavItem,
    name: "Danh sách lớp - Mipec",
    to: "/classes?address_id=1",
    icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
    permision: "KES_TEACHER",
  },
  {
    component: CNavItem,
    name: "Báo cáo lớp học - Mipec",
    to: "/report-classes?address_id=1",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    permision: "KES_TEACHER",
  },
  {
    component: CNavItem,
    name: "Báo cáo lịch học - Mipec",
    to: "/report-students?address_id=1",
    icon: <CIcon icon={cilActionRedo} customClassName="nav-icon" />,
    permision: "KES_STUDENT",
  },
  {
    component: CNavItem,
    name: "Quản lý thu - Mipec",
    to: "/transactions?type=2&address_id=1",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    permision: "KES_MONEY",
  },
  {
    component: CNavItem,
    name: "Quản lý chi - Mipec",
    to: "/out-transactions?type=1&address_id=1",
    icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
    permision: "KES_MONEY",
  },
  {
    component: CNavTitle,
    name: "KES LONG BIÊN",
    permision: "KES_ADMIN",
  },
  {
    component: CNavItem,
    name: "Danh sách lớp - LB",
    to: "/classes-longbien?address_id=2",
    icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
    permision: "KES_TEACHER",
  },
  {
    component: CNavItem,
    name: "Báo cáo lớp học - LB",
    to: "/report-classes-longbien?address_id=2",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    permision: "KES_TEACHER",
  },
  {
    component: CNavItem,
    name: "Báo cáo lịch học - LB",
    to: "/report-students-longbien?address_id=2",
    icon: <CIcon icon={cilActionRedo} customClassName="nav-icon" />,
    permision: "KES_STUDENT",
  },
  {
    component: CNavItem,
    name: "Quản lý thu - LB",
    to: "/transactions-longbien?type=2&address_id=2",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    permision: "KES_MONEY",
  },
  {
    component: CNavItem,
    name: "Quản lý chi - LB",
    to: "/out-transactions-longbien?type=1&address_id=2",
    icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
    permision: "KES_MONEY",
  },
  {
    component: CNavTitle,
    name: "PDFS",
    permision: "SUPER_ADMIN",
  },
  {
    component: CNavItem,
    name: "Reports",
    to: "/pdfs/reports",
    icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
    permision: "SUPER_ADMIN",
  },
];

export default _nav;
