import { Api } from "modules/base";
import { URL_API } from "modules/common";

export const apiGetTailieuCaptchaUrl = (params: any): Promise<any> => {
  return Api.get(`${URL_API}/v2/pdfs/get-tailieu-captcha`, params);
};

export const apiGetTailieuFile = (params: any): Promise<any> => {
  return Api.get(`${URL_API}/v2/pdfs/get-tailieu-file`, params);
};
