export const WEBSOCKET_URL =
  process.env.REACT_APP_WEBSOCKET_URL || "http://localhost:8082/ws/";

export const KEYS = {
  USER: "USER",
  ROLE: "ROLE",
  CHAT: "CHAT",
};

export const SYSTEMS = {
  YO: 0,
  KES: 1,
  ALL: 2,
};

export const ROLES = [
  {
    id: 2,
    name: "Học sinh MIPEC",
  },
  {
    id: 3,
    name: "Học sinh Long Biên",
  },
  {
    id: 4,
    name: "Giáo viên",
  },
];

export const PERMISIONS: {
  id: string;
  name: string;
  system: number;
  disabled?: boolean;
}[] = [
  {
    id: "1015c0b9-afd4-457d-8dd6-22caeefc0a5e",
    name: "KES_TEACHER",
    system: SYSTEMS.KES,
  },
  {
    id: "1015c0b9-afd4-457d-8dd6-22caeefc0a5f",
    name: "KES_STUDENT",
    system: SYSTEMS.KES,
  },
  {
    id: "1015c0b9-afd4-457d-8dd6-22caeefc0a5d",
    name: "KES_ADMIN",
    system: SYSTEMS.KES,
  },
  {
    id: "1015c0b9-afd4-457d-8dd6-22caeefc0a64",
    name: "KES_MONEY",
    system: SYSTEMS.KES,
  },
  {
    id: "1015c0b9-afd4-457d-8dd6-22caeefc0a65",
    name: "KES_ACCOUNT",
    system: SYSTEMS.KES,
  },
  {
    id: "1015c0b9-afd4-457d-8dd6-22caeefc0a5c",
    name: "YO_REVIEW",
    system: SYSTEMS.YO,
  },
  {
    id: "1015c0b9-afd4-457d-8dd6-22caeefc0a5a",
    name: "YO_ADMIN",
    system: SYSTEMS.YO,
  },
  {
    id: "1015c0b9-afd4-457d-8dd6-22caeefc0a60",
    name: "YO_REVIEW_ANDROID",
    system: SYSTEMS.YO,
  },
  {
    id: "1015c0b9-afd4-457d-8dd6-22caeefc0a61",
    name: "YO_REVIEW_IOS",
    system: SYSTEMS.YO,
  },
  {
    id: "1015c0b9-afd4-457d-8dd6-22caeefc0a62",
    name: "YO_REVIEW_DUETCAT",
    system: SYSTEMS.YO,
  },
  {
    id: "1015c0b9-afd4-457d-8dd6-22caeefc0a5b",
    name: "SUPER_ADMIN",
    system: SYSTEMS.ALL,
  },
];
