import { Api } from "modules/base";
import { URL_API } from "modules/common";

export const apiGetUsers = (params: any): Promise<any> => {
  return Api.get(`${URL_API}/users`, params);
};

export const apiAddUser = (params: any): Promise<any> => {
  return Api.post(`${URL_API}/users`, params);
};

export const apiUpdateUser = (
  params: { is_enable: number; roles: string[] },
  id: string
): Promise<any> => {
  return Api.put(`${URL_API}/users/${id}`, params);
};

export const apiAddPermision = (params: any): Promise<any> => {
  return Api.post(`${URL_API}/users/add-permisions`, params);
};

export const apiDeletePermision = (params: any): Promise<any> => {
  return Api.post(`${URL_API}/users/delete-permisions`, params);
};
