import { Store } from "redux";
import { MiddlewareRegistry } from "modules/base";
import { ACTION_TYPES } from "./actionTypes";
import { apiGetTailieuCaptchaUrl } from "./services";
import { setTailieuData } from "./actions";
import { URL_API } from "modules/common";

export const middleware =
  ({ dispatch, getState }: Store) =>
    (next: Function) =>
      async (action: { type: any; id?: string; params: any; captcha?: string }) => {
        next(action);
        switch (action.type) {
          case ACTION_TYPES.GET_TAILIEU_CAPTCHA: {
            dispatch(
              setTailieuData({
                isCallingCaptcha: true,
                captchaUrl: "",
                fileUrl: "",
                isGettingFile: false,
              })
            );
            apiGetTailieuCaptchaUrl({ id: action.id }).then((rs) => {
              if (rs.status) {
                dispatch(setTailieuData({ ...rs, isCallingCaptcha: false }));
              } else {
                alert("File không tồn tại!");
                dispatch(setTailieuData({ isCallingCaptcha: false }));
              }
            });
            return;
          }
          case ACTION_TYPES.GET_TAILIEU_FILE: {
            const { tailieu } = getState().tools;
            if (!tailieu.isGettingFile) {
              dispatch(setTailieuData({ isGettingFile: true }));
              let url = `${URL_API}/v2/pdfs/get-tailieu-file?`;
              for (const [key, value] of Object.entries({
                ...tailieu.q,
                captcha: action.captcha,
              })) {
                url = `${url}${key}=${value}&`;
              }
              dispatch(setTailieuData({ isGettingFile: false, fileUrl: url }));
            }
            return;
          }
        }
      };

MiddlewareRegistry.register(middleware);
