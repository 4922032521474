import { ACTION_TYPES } from "./actionTypes";

export const getCaptchaSignin = (params: any) => {
  return {
    type: ACTION_TYPES.GET_CAPTCHA_SINGN,
    params,
  };
};

export const setCaptchaSignin = (data: any) => {
  return {
    type: ACTION_TYPES.SET_CAPTCHA_SINGN,
    data,
  };
};

export const signinTailieu = (params: any) => {
  return {
    type: ACTION_TYPES.SIGNIN_TAILIEU,
    params,
  };
};

export const crawlPdf = () => {
  return {
    type: ACTION_TYPES.ADD_PDF_CRAWL,
  };
};

export const setCrawlPdfs = (data: {}) => {
  return {
    type: ACTION_TYPES.SET_PDF_CRAWLS,
    data
  };
};

export const removeCrawlPdf = (data: string) => {
  return {
    type: ACTION_TYPES.REMOVE_PDF_CRAWL,
    data
  };
};

export const updateCrawlPdf = (params: { captcha: string, id?: string }) => {
  return {
    type: ACTION_TYPES.UPDATE_PDF_CRAWL,
    params
  };
};

export const setFileCrawl = (data: string) => {
  return {
    type: ACTION_TYPES.SET_FILE_CRAWL,
    data
  };
};