import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReviewStoreType } from "../types/reviewStore";
import { getRateComments } from "../redux/actions";
import { useHistory, useLocation } from "react-router-dom";
import { Table } from "antd";
import { RateCommentsColumns } from "./RateCommentsColumns";

const RateComments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);

  const { rateComments } = useSelector(
    (state: { reviews: ReviewStoreType }) => state.reviews
  );

  const [pagination, setPagination] = useState({
    page: 1,
    current: 1,
    total: rateComments.total,
    pageSize: 100,
  });

  useEffect(() => {
    dispatch(
      getRateComments({
        page: query.get("page"),
        size: parseInt(query.get("pageSize") || 100),
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPagination({
      ...pagination,
      total: rateComments.total,
      current: parseInt(query.get("page") || 1),
      pageSize: parseInt(query.get("pageSize") || 100),
    });
  }, [rateComments]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange: any = (pagination: any, filters: any) => {
    history.push(
      `/rate-comments?page=${pagination.current}&pageSize=${pagination?.pageSize}`
    );
    dispatch(
      getRateComments({
        page: pagination.current,
        size: pagination?.pageSize,
      })
    );
  };

  let rootEl: any = document.getElementById("root")?.offsetHeight;
  let reviewsEl: any = 210;
  try {
    reviewsEl = document.getElementById("reviews")?.offsetTop;
  } catch (error) {}

  return (
    <>
      <Table
        dataSource={rateComments.data.map((d: any, index: any) => {
          return {
            ...d,
            key: d.comment_id,
            index: index + 1 + (pagination.current - 1) * pagination.pageSize,
          };
        })}
        columns={RateCommentsColumns()}
        loading={rateComments.loading}
        pagination={pagination}
        onChange={handleTableChange}
        scroll={{ y: rootEl - reviewsEl - 210 }}
      />
    </>
  );
};

export default RateComments;
