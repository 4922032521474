import { Api } from "modules/base";
import { URL_API } from "modules/common";

export const apiGetPdfReports = (params: any): Promise<any> => {
  return Api.get(`${URL_API}/v2/pdfs/report`, params);
};

export const apiCountPdfByCreatedAt = (params: any): Promise<any> => {
  return Api.get(`${URL_API}/v2/pdfs/reportByDate`, params);
};

export const apiCountPdfByCreatedAtV2 = (params: any): Promise<any> => {
  return Api.get(`${URL_API}/v2/pdfs/countByDate`, params);
};

export const apiGetCaptchaUrl = (params: any): Promise<any> => {
  return Api.get(`${URL_API}/v2/pdfs/get-captcha`, params);
};

export const apiGetFileUrl = (params: any): Promise<any> => {
  return Api.get(`${URL_API}/v2/pdfs/verify-captcha`, params);
};

export const apiGetCaptchaSigninUrl = (params: any): Promise<any> => {
  return Api.get(`${URL_API}/v2/pdfs/get-captcha-signin`, params);
};

export const apiSigninTailieu = (params: any): Promise<any> => {
  return Api.get(`${URL_API}/v2/pdfs/signin`, params);
};
