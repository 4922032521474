import { Store, Dispatch } from "redux";
import { ACTION_TYPES } from "./actionTypes";
import { MiddlewareRegistry } from "modules/base";
import {
  apiGetUsers,
  apiAddPermision,
  apiDeletePermision,
  apiUpdateUser,
  apiAddUser,
} from "./services";
import { setUser, setUsers } from "./actions";

export const middleware =
  ({ dispatch, getState }: Store) =>
    (next: Function) =>
      async (action: any) => {
        next(action);
        switch (action.type) {
          case ACTION_TYPES.UPDATE_USER: {
            return await handleUpdateUser(action.params, action.id);
          }
          case ACTION_TYPES.UPDATE_PERMISION_USER: {
            return await handleUpdatePermisionUser(dispatch, action);
          }
          case ACTION_TYPES.ADD_USER: {
            return await handleAddUser(action.params, action.cb);
          }
          case ACTION_TYPES.ADD_PERMISION: {
            dispatch(setUser(action.data));
            apiAddPermision({ userId: action.data.id, role: "YO_REVIEW" }).then(
              (rs) => {
                alert("Thêm quyền thành công!");
                dispatch(
                  setUser({ ...action.data, roles: "YO_REVIEW", loading: false })
                );
              }
            );
            return;
          }
          case ACTION_TYPES.DELETE_PERMISION: {
            dispatch(setUser(action.data));
            apiDeletePermision({ userId: action.data.id, role: "YO_REVIEW" }).then(
              (rs) => {
                alert("Xoá quyền thành công!");
                dispatch(setUser({ ...action.data, roles: "", loading: false }));
              }
            );
            return;
          }
          case ACTION_TYPES.GET_USERS: {
            dispatch(
              setUsers({
                data: [],
                page: action.data,
                loading: true,
              })
            );
            apiGetUsers({
              page: action.data,
              size: action.size,
            })
              .then((rs) => {
                dispatch(setUsers({ ...rs, loading: false }));
              })
              .catch((err) => { });
          }
        }
      };

const handleUpdateUser = async (
  params: { is_enable: number; roles: string[] },
  id: string
) => {
  try {
    await apiUpdateUser(params, id);
    alert("Chỉnh sửa quyền thành công!");
  } catch (error) { }
};

const handleUpdatePermisionUser = async (
  dispatch: Dispatch,
  data: { roles: string; id: string }
) => {
  try {
    dispatch(setUser(data));
  } catch (error) { }
};

const handleAddUser = async (
  params: {
    is_enable: number;
    roles: string[];
    type: number;
    name: string;
    email: string;
  },
  cb: Function
) => {
  try {
    const rs = await apiAddUser(params);
    if (rs.status) {
      alert("Thêm tài khoản thành công!");
      cb();
    } else {
      alert(rs.msg);
    }
  } catch (error) {
    alert("Thêm tài khoản không thành công!");
  }
};

MiddlewareRegistry.register(middleware);
