export const RATES = [
  {
    text: "1",
    value: 1,
  },
  {
    text: "2",
    value: 2,
  },
  {
    text: "3",
    value: 3,
  },
  {
    text: "4",
    value: 4,
  },
  {
    text: "5",
    value: 5,
  },
];

export const RATE_COMMENT = {
  GOOD: 1,
  BAD: 2,
  NA: 0,
};
