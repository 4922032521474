export const ACTION_TYPES = {
  GET_CAPTCHA_SINGN: Symbol("SET_CRAWL"),
  SET_CAPTCHA_SINGN: Symbol("SET_CRAWL"),
  SIGNIN_TAILIEU: Symbol("SET_CRAWL"),
  ADD_PDF_CRAWL: Symbol("ADD_PDF_CRAWL"),
  REMOVE_PDF_CRAWL: Symbol("REMOVE_PDF_CRAWL"),
  UPDATE_PDF_CRAWL: Symbol("UPDATE_PDF_CRAWL"),
  SET_PDF_CRAWLS: Symbol("SET_PDF_CRAWLS"),
  SET_FILE_CRAWL: Symbol("SET_FILE_CRAWL"),
};
