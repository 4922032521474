import { Api } from "modules/base";
import { URL_API } from "modules/common";
import {
  PostClassAttendance,
  addClassParamsType,
  addTransactionParamsType,
  getClassesParamsType,
  getOptionParamsType,
  getReportStudentsParamsType,
  getReportTeachersParamsType,
  getReportTransactionsParamsType,
  getTransactionsParamsType,
} from "../types/httpRequest";

export const apiGetClasses = (params: getClassesParamsType): Promise<any> => {
  return Api.get(`${URL_API}/classes`, params);
};

export const apiGetReportTeachers = (
  params: getReportTeachersParamsType
): Promise<any> => {
  return Api.get(`${URL_API}/classes/report-teacher`, params);
};

export const apiGetReportStudents = (
  params: getReportStudentsParamsType
): Promise<any> => {
  return Api.get(`${URL_API}/classes/report-student`, params);
};

export const apiAddClass = (params: addClassParamsType): Promise<any> => {
  return Api.post(`${URL_API}/classes`, params);
};

export const apiDeleteClass = (id: any, type: number): Promise<any> => {
  return Api.delete(`${URL_API}/classes/${id}`, { type });
};

export const apiGetClassAttendance = (id: string): Promise<any> => {
  return Api.get(`${URL_API}/classes/${id}/attendance`, {});
};

export const apiPostClassAttendance = (
  id: string,
  data: PostClassAttendance
): Promise<any> => {
  return Api.post(`${URL_API}/classes/${id}/attendance`, { data });
};

export const apiUpdateClass = (params: any): Promise<any> => {
  return Api.put(`${URL_API}/classes/${params.id}`, params);
};

export const apiGetOptions = (params: getOptionParamsType): Promise<any> => {
  return Api.get(`${URL_API}/classes/options`, params);
};

export const apiGetTransactions = (
  params: getTransactionsParamsType
): Promise<any> => {
  return Api.get(`${URL_API}/classes/transactions`, params);
};

export const apiUpdateTransaction = (params: any): Promise<any> => {
  return Api.put(`${URL_API}/classes/transactions/${params.id}`, params);
};

export const apiAddTransaction = (
  params: addTransactionParamsType
): Promise<any> => {
  return Api.post(`${URL_API}/classes/transactions`, params);
};

export const apiGetReportTransactions = (
  params: getReportTransactionsParamsType
): Promise<any> => {
  return Api.get(`${URL_API}/classes/report-transactions`, params);
};
